import { put, takeLatest } from 'redux-saga/effects';
import { createAppTableSaga, createAppTableStore } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';
import { convertMinsToHrsMins } from 'utils/string.utils';

export const requestTimelogSlice = createAppTableStore({
  name: 'requestTimelog' as const,
  url: apiRoutes.TIME_LOG_REPORT,
  initialState: {
    fixedParams: { 'filters[status][]': 'completed' },
    totalTime: null as null | string,
  },
  reducers: {
    setTotalTime: (state, { payload }: { payload: string; type: string }) => {
      return {
        ...state,
        totalTime: payload,
      };
    },
  },
});

function* handleApiResponseSagaListener({ payload }: { payload: any }) {
  yield put(requestTimelogSlice.actions.setTotalTime(convertMinsToHrsMins(Number(payload.requests_total_time ?? 0))));
}

export const requestTimelogSaga = createAppTableSaga(requestTimelogSlice, function* customGenerator() {
  yield takeLatest(requestTimelogSlice.actions.handleApiResponse, handleApiResponseSagaListener);
});

export const requestTimelogReducer = requestTimelogSlice.reducer;
