import { SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Dropdown, notification as notificationAntd } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import createInfinityScrollApiContext from 'components/InfiniteScrollApi/createInfinityScrollApiContext';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import apiRequests from 'utils/api';
import useWebSocket from 'utils/useWebSocket';
import apiRoutes from 'config/apiRoute';
import DropdownHeader from './DropdownHeader';
import DropdownBody from './DropdownBody';
import { Notification } from './DropdownCard';
import DropdownFooter from './DropdownFooter';
import styles from './index.module.less';

const BellNotificationInfinityScroll = createInfinityScrollApiContext<Notification>();

type NotificationStatus = 'all' | 'read' | 'unread';

interface BellNotificationContainerProps {
  status: NotificationStatus;
  setStatus: (value: SetStateAction<NotificationStatus>) => void;
}

const BellNotificationContainer = ({ status, setStatus }: BellNotificationContainerProps) => {
  const user = useSelector((store: any) => store.auth.user);

  const { updateAllItems, updateItem, removeItem, removeAllItems, addItem, resetFetch, items, initialFetch } = useContext(
    BellNotificationInfinityScroll.Context,
  );
  const [countUnread, setCountUnread] = useState(0);
  const history = useHistory();

  const onMarkAllRead = () => {
    apiRequests.post(`${apiRoutes.NOTIFICATIONS}/read-all`).catch((error) => asyncErrorHandler(error));

    if (status === 'read') {
      resetFetch();
    }

    if (status === 'unread') {
      removeAllItems();
    }

    if (status === 'all') {
      updateAllItems({ read_at: dayjs().format() });
    }

    setCountUnread(0);
  };

  const onMarkRead = (target: Notification) => {
    apiRequests.post(`${apiRoutes.NOTIFICATIONS}/${target.id}/read`).catch((error) => asyncErrorHandler(error));

    if (target.data.has_mention) {
      apiRequests.post(`${apiRoutes.MENTIONS}/${target.data.resource_id}/read`).catch((error) => asyncErrorHandler(error));
    }

    if (status === 'unread') {
      removeItem(target);
    }

    if (status === 'all') {
      updateItem(target, { read_at: dayjs().format() });
    }

    setCountUnread((old) => old - 1);
  };

  const onMarkUnread = (target: Notification) => {
    apiRequests.post(`${apiRoutes.NOTIFICATIONS}/${target.id}/unread`).catch((error) => asyncErrorHandler(error));

    if (status === 'read') {
      removeItem(target);
    }

    if (status === 'all') {
      updateItem(target, { read_at: null });
    }

    setCountUnread((old) => old + 1);
  };

  const onDelete = (target: Notification) => {
    apiRequests.delete(`${apiRoutes.NOTIFICATIONS}/${target.id}`).catch((error) => asyncErrorHandler(error));

    removeItem(target);

    if (!target.read_at) {
      setCountUnread((old) => old - 1);
    }
  };

  useEffect(() => {
    const getCountUnread = async () => {
      try {
        const response = await apiRequests.get(`${apiRoutes.NOTIFICATIONS}/count`);

        setCountUnread(response.data.data.unread);
      } catch (error) {
        asyncErrorHandler(error);
      }
    };

    getCountUnread();
  }, []);

  useWebSocket({
    channelName: `users.${user.uuid}`,
    notification: async (notify: any) => {
      setCountUnread((old) => old + 1);

      notificationAntd.open({
        message: notify.title,
        description: notify.content,
        style: { overflow: 'hidden', maxHeight: '138px' },
        onClick: () => {
          const url = new URL(notify.action_url ?? '/');
          if (window.location.hostname === url.hostname) {
            history.push(url.pathname + url.search);
          } else {
            window.open(notify.action_url, '_blank');
          }
        },
        placement: 'topRight',
        icon: <BellOutlined />,
      });

      if (!initialFetch || status === 'read') {
        return;
      }

      addItem({
        id: notify.id,
        number: items?.[0]?.number + 1,
        type: notify.type,
        read_at: null,
        created_at: notify.created_at,
        updated_at: notify.created_at,
        notifiable_id: user.uuid,
        notifiable_type: 'App\\Models\\Auth\\User',
        data: {
          title: notify.title,
          sub_title: notify.sub_title,
          content: notify.content,
          action_url: notify.action_url,
          resource_id: notify.resource_id,
          has_mention: notify.has_mention,
        },
      });
    },
  });

  return (
    <Dropdown
      destroyPopupOnHide
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <div className={styles.dropdownOverlay}>
          <DropdownHeader onMarkAllRead={onMarkAllRead} />

          <DropdownBody
            context={BellNotificationInfinityScroll.Context}
            onMarkAsRead={onMarkRead}
            onMarkAsUnread={onMarkUnread}
            onDelete={onDelete}
          />

          <DropdownFooter className={styles.dropdownFooter} notificationStatus={status} onNotificationStatusChange={setStatus} />
        </div>
      }
    >
      <Badge count={countUnread} offset={[-4, 5]}>
        <Button className="w-full h-auto" type="link" icon={<BellOutlined style={{ color: '#9A9A9A', fontSize: 30 }} />} />
      </Badge>
    </Dropdown>
  );
};

const BellNotification = () => {
  const [status, setStatus] = useState<NotificationStatus>('all');
  const params = useMemo(() => {
    if (status !== 'all') {
      return { filter: status };
    }

    return undefined;
  }, [status]);

  return (
    <BellNotificationInfinityScroll.Provider url={apiRoutes.NOTIFICATIONS} params={params} sortKey="number">
      <BellNotificationContainer status={status} setStatus={setStatus} />
    </BellNotificationInfinityScroll.Provider>
  );
};

export default BellNotification;
