import { FC, useState } from 'react';
import moment from 'moment';
import { DatePicker, TimePicker } from 'antd';
import { TbCalendarEvent } from 'react-icons/tb';
import { FormBuilderFieldDate } from 'types/formBuilder';

interface FieldDateProps {
  id?: string;
  value?: any;
  data: FormBuilderFieldDate;
  disabled: boolean;
  onChange?: any;
}

const FieldDate: FC<FieldDateProps> = ({ id, value, data, disabled, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      id={id}
      className="flex flex-wrap items-center rounded px-3 py-1"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderRadius: 4,
        padding: '4px 12px',
        border: '1px solid rgba(0, 68, 204, 0.2)',
        background: 'rgba(0, 68, 204, 0.05)',
      }}
      onClick={() => !disabled && setOpen(true)}
    >
      <TbCalendarEvent style={{ color: '#0044CC' }} />

      {data.mode === 'simple' && (
        <DatePicker
          open={open}
          value={value}
          className={disabled ? 'read-only' : undefined}
          style={{
            border: 'none',
            background: 'transparent',
            boxShadow: 'none',
          }}
          format="MMM DD, YYYY"
          suffixIcon={null}
          placeholder={data.placeholder}
          disabledDate={(date) => date.isBefore(moment().startOf('day'))}
          onChange={onChange}
          onOpenChange={setOpen}
        />
      )}

      {data.mode === 'range' && (
        <DatePicker.RangePicker
          open={open}
          value={value}
          className={`ant-picker-short-format${disabled ? ' read-only' : ''}`}
          style={{
            border: 'none',
            background: 'transparent',
            boxShadow: 'none',
          }}
          suffixIcon={null}
          separator="-"
          format="MMM DD, YYYY"
          disabledDate={(date) => date.isBefore(moment().startOf('day'))}
          onChange={onChange}
          onOpenChange={setOpen}
        />
      )}

      {data.mode === 'time' && (
        <TimePicker
          open={open}
          value={value}
          className={disabled ? 'read-only' : undefined}
          onChange={onChange}
          onOpenChange={setOpen}
        />
      )}
    </div>
  );
};

export default FieldDate;
