import { createAppTableSaga, createAppTableStore, createPersist } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';
import { parseProjectTitle } from '../../../utils/string.utils';

export const projectTimeReportSlice = createAppTableStore({
  name: 'projectsTimeReport' as const,
  url: apiRoutes.PROJECT_TIME_REPORT,
  initialState: {
    params: {
      filters: {
        'projects.status': [{ value: 'paused' }, { value: 'waiting_to_launch' }, { value: 'in_progress' }, { value: 'active' }],
      },
      sort: { column: 'title', order: 'ascend' },
    },
    paramFilterFields: {
      advertising: 'filters[advertising][]',
    },
    serverControl: false,
  },
  transformData: (data) => {
    return data.map((item) => {
      return {
        ...item,
        budget: item.time_report.budget,
        estimated: item.time_report.estimated,
        estimated_original: item.time_report.estimated_original,
        spent: item.time_report.spent,
        available: item.time_report.available,
        business_name: item.customer?.business_name,
        parser_title: parseProjectTitle(item),
      };
    });
  },
});

export const projectsTimeReportSaga = createAppTableSaga(projectTimeReportSlice);

export const projectsTimeReportReducer = createPersist(projectTimeReportSlice);
