import { CSSProperties, FC, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, TableProps } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { checkIsSafari } from 'utils/extra.utils';
import type { RootState } from 'store';
import EditableRow from './EditableRow';
import EditableCell from './EditableCell';
import useColumns, { AppTableColumn } from './useColumns';
import styles from './appTable.module.less';
import type { AppTableInitialState, AppTableSlice } from './store';
import AlertSelectedRows from './AlertSelectedRows';
import AppTablePagination from './AppTablePagination';
import useUrlParamFilters from './useUrlParamFilters';

interface AppTableProps {
  storeSlice: AppTableSlice;
  columns: AppTableColumn[];
  className?: string;
  containerStyle?: CSSProperties;
  widthX?: string | number;
  widthY?: string | number;
  rowKey?: string;
  size?: SizeType;
  textCount?: string;
  prefixPagination?: string;
  childrenColumnName?: string;
  triggerSilenceReload?: number;
  editable?: boolean;
  fullPage?: boolean;
  hideActionColumn?: boolean;
  hideRowSelection?: boolean;
  hidePagination?: boolean;
  rowClassName?: TableProps<any>['rowClassName'];
  onRowUpdate?: (uuid: string, values: Record<string, any>) => void;
  onRowClick?: (row: Record<string, any>) => void;
  onDoubleClick?: (row: Record<string, any>) => void;
  onRenderData?: (data: Record<string, any>[]) => Record<string, any>[];
}

const AppTable: FC<AppTableProps> = ({
  storeSlice,
  columns: propColumns,
  className,
  containerStyle,
  widthX,
  widthY,
  rowKey = 'uuid',
  size = 'small',
  textCount,
  prefixPagination,
  childrenColumnName,
  triggerSilenceReload,
  editable = false,
  fullPage = false,
  hideActionColumn = false,
  hideRowSelection = false,
  hidePagination = false,
  rowClassName,
  onRowUpdate,
  onRowClick,
  onDoubleClick,
  onRenderData,
}) => {
  const store = useSelector((globalStore: RootState) => globalStore[storeSlice.name] as AppTableInitialState);
  const triggerSilenceReloadRef = useRef(triggerSilenceReload);

  useUrlParamFilters({ store, storeSlice });

  const dataSource = useMemo(() => {
    const newData =
      store.serverControl || !store.pagination
        ? store.data
        : store.data.slice(
            store.pagination.pageSize * (store.pagination.current - 1),
            store.pagination.pageSize * store.pagination.current,
          );

    return onRenderData ? onRenderData(newData) : newData;
  }, [store.serverControl, store.pagination, store.data, onRenderData]);

  const dispatch = useDispatch();

  if (triggerSilenceReloadRef.current !== triggerSilenceReload) {
    triggerSilenceReloadRef.current = triggerSilenceReload;

    if (!store.loading) {
      dispatch(storeSlice.actions.fetch({ silenceLoading: true }));
    }
  }

  const columns = useColumns({
    columns: propColumns,
    sort: store.params.sort,
    editable,
    onSave: onRowUpdate,
  });

  const renderComponents: TableProps<any>['components'] = editable
    ? {
        body: {
          row: EditableRow,
          cell(props: any) {
            if (!props.editable) {
              return <td {...props} />;
            }

            const currentEl = columns.find((el) => el.dataIndex === props.dataIndex);

            return (
              <EditableCell
                {...props}
                editComponent={currentEl?.editComponent}
                beforeSave={currentEl?.beforeSave}
                onToggleEdit={currentEl?.onToggleEdit}
              />
            );
          },
        },
      }
    : undefined;

  const scrollX = !store.data?.length ? widthX ?? true : 'max-content';
  const defaultScrollY = fullPage ? 'calc(100% - 24px)' : window.innerHeight - 450;

  useEffect(() => {
    dispatch(storeSlice.actions.fetch());
  }, [dispatch, storeSlice]);

  return (
    <div className={styles.tableContainer} style={containerStyle}>
      <AlertSelectedRows
        rowsTotal={store.pagination?.total ?? store.data.length}
        dataLength={store.data.length}
        selectedRowKeys={store.selectedRows}
        selectedAllPages={store.selectedAllPages}
        onToggleSelectedAllPages={() => dispatch(storeSlice.actions.toggleSelectedAllPages({ rowKey }))}
      />

      <Table
        columns={[
          ...columns,
          ...(onDoubleClick && !hideActionColumn
            ? [
                {
                  width: 40,
                  title: '',
                  dataIndex: 'action',
                  fixed: 'right' as const,
                  render: (_: any, row: any) => {
                    return <Button size="small" type="link" icon={<EyeOutlined />} onClick={() => onDoubleClick(row)} />;
                  },
                },
              ]
            : []),
        ]}
        dataSource={dataSource}
        loading={store.loading && !store.silenceLoading}
        size={size}
        className={className}
        rowClassName={rowClassName}
        scroll={{ y: widthY ?? defaultScrollY, x: editable && checkIsSafari() ? widthX ?? true : scrollX }}
        components={renderComponents}
        pagination={false}
        showSorterTooltip={false}
        rowKey={rowKey}
        childrenColumnName={childrenColumnName}
        rowSelection={
          hideRowSelection
            ? undefined
            : {
                selectedRowKeys: store.selectedRows,
                onChange: (keys) => {
                  dispatch(storeSlice.actions.setSelectedRows(keys));
                },
                onSelectAll: (selected) => {
                  if (selected) {
                    dispatch(storeSlice.actions.setSelectedRows(store.data.map((el: any) => el[rowKey])));
                  } else {
                    dispatch(storeSlice.actions.setSelectedRows([]));
                  }
                },
              }
        }
        onChange={(_pagination, _filters, sort) => {
          if (Array.isArray(sort)) {
            return;
          }

          let field = Array.isArray(sort.field) ? sort.field.join('.') : sort.field;

          field = (sort.column as any)?.sorterKey ?? field;

          dispatch(
            storeSlice.actions.setSort(
              field && sort.order
                ? {
                    column: field.toString(),
                    order: sort.order,
                  }
                : null,
            ),
          );
        }}
        onRow={(row) => ({
          onClick: () => onRowClick?.(row),
          onDoubleClick: () => onDoubleClick?.(row),
        })}
      />

      <AppTablePagination
        storeSlice={storeSlice}
        size={size}
        textCount={textCount}
        prefixPagination={prefixPagination}
        hidePagination={hidePagination}
      />
    </div>
  );
};

export default AppTable;

export { styles as appTableStyles };
