import { FC } from 'react';
import { Button, FormInstance } from 'antd';
import { getFormData } from './FormSubmitButton';

interface FormDraftButtonProps {
  form: FormInstance;
  saving?: boolean;
  onDraft: (values: any) => void;
}

const FormDraftButton: FC<FormDraftButtonProps> = ({ form, saving, onDraft }) => {
  return (
    <Button
      type="default"
      loading={saving}
      onClick={() =>
        getFormData(form)
          .then((values) => onDraft(values))
          .catch(() => {
            //
          })
      }
    >
      Save draft
    </Button>
  );
};

export default FormDraftButton;
