/* eslint-disable react/no-unused-prop-types */
import { FC } from 'react';
import { Empty, FormInstance, Upload } from 'antd';
import UploadDragger from 'components/FileUpload/UploadDragger';
import ButtonDownloadAllFiles, { ButtonDownloadAllFilesProps } from 'components/ButtonDownloadAllFiles';
import IconUploadFile from 'components/IconUploadFile';
import { removeImage } from 'utils/string.utils';
import useUploadDownload from 'utils/useUploadDownload';
import { FormBuilderFieldUpload } from 'types/formBuilder';
import { UploadFileApi } from 'types/upload-type';

interface FieldUploadProps {
  id?: string;
  value?: UploadFileApi[];
  data: FormBuilderFieldUpload;
  form: FormInstance;
  disabled: boolean;
  showDownloadIcon?: boolean;
  resource?: ButtonDownloadAllFilesProps['resource'];
  resourceIds?: ButtonDownloadAllFilesProps['ids'];
  onChange?: (values: UploadFileApi[]) => void;
  onRequest?: (response: { url: string; uuid: string }) => Promise<unknown>;
  onRemove?: (upload: UploadFileApi, textEditorUpdated: boolean) => void;
}

const FieldUpload: FC<FieldUploadProps> = ({
  id,
  value = [],
  form,
  disabled,
  showDownloadIcon,
  resource,
  resourceIds,
  onChange,
  onRequest,
  onRemove,
}) => {
  const { onDownload } = useUploadDownload();

  if (disabled) {
    if (!value?.length) {
      return <Empty description="No files" />;
    }

    return (
      <>
        {resource && resourceIds && <ButtonDownloadAllFiles resource={resource} ids={resourceIds} />}

        <Upload
          listType="picture"
          style={{ display: 'grid' }}
          showUploadList={{
            showDownloadIcon: true,
            showRemoveIcon: false,
          }}
          fileList={value}
          iconRender={(upload: UploadFileApi) => <IconUploadFile file={upload} />}
          onDownload={onDownload}
          onPreview={(file) => {
            if (file.status === 'done') {
              window.open(!file.response?.stated ? file.url : `/file-viewer/${file.response.uuid}`, '_blank');
            }
          }}
        />
      </>
    );
  }

  return (
    <UploadDragger
      containerId={id}
      uploads={value}
      showDownloadIcon={showDownloadIcon}
      showRemoveIcon
      setUploads={(newUploads) => {
        onChange?.(newUploads);
      }}
      onRequest={onRequest}
      onRemove={(upload) => {
        const answers = form.getFieldsValue();

        let textEditorUpdated = false;

        Object.keys(answers).forEach((key: any) => {
          const item = answers[key];

          if (typeof item === 'string' && upload.response && item.includes(upload.response.url)) {
            textEditorUpdated = true;
            form.setFieldsValue({
              [key]: removeImage(item, upload.response.url),
            });
          }
        });

        onRemove?.(upload, textEditorUpdated);
      }}
    />
  );
};

export default FieldUpload;
