import { ReactNode, FC, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { EditableContext } from './EditableRow';

interface EditableCellProps {
  title: ReactNode;
  editable: boolean;
  children?: ReactNode;
  dataIndex: any;
  record: any;
  editComponent: any;
  onToggleEdit: any;
  onSave: (uuid: string, values: Record<string, any>) => void;
  beforeSave: (record: Record<string, any>) => Record<string, any>;
}

const EditableCell: FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  editComponent,
  onToggleEdit,
  onSave,
  beforeSave,
  ...restProps
}) => {
  const [activeCell, setActiveCell] = useState<null | string>(null);
  const inputRef = useRef<any>(null);

  const form = useContext(EditableContext);

  const editing = `${record?.uuid} ${dataIndex}` === activeCell;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      form.setFieldsValue({ [dataIndex]: record[dataIndex] ?? undefined });
      if (onToggleEdit) {
        onToggleEdit(record, form);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const cancel = () => {
    setActiveCell(null);
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      if (Object.keys(values)?.length === 0) {
        return;
      }

      onSave(record.uuid, beforeSave ? beforeSave(values) : values);
      cancel();
    } catch (err) {
      asyncErrorHandler(err);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24, minHeight: 10 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Space>
          {editComponent ? (
            editComponent(record, form, save, cancel)
          ) : (
            <Form.Item style={{ margin: 0 }} name={dataIndex}>
              <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
          )}
          <Button type="text" icon={<SaveOutlined className="primary" />} onClick={save} />
        </Space>
      </div>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24, minHeight: 10 }}>
        {children}
      </div>
    );
  }

  return (
    <td
      onClick={() => {
        setActiveCell(`${record?.uuid} ${dataIndex}`);
      }}
      {...restProps}
    >
      {childNode}
    </td>
  );
};

export default EditableCell;
