import { FC, ReactNode, useRef } from 'react';
import moment from 'moment';
import { Form } from 'antd';
import { Rule } from 'antd/es/form';
import { FormBuilderFormData } from 'types/formBuilder';
import { mapModelToUploadFileApi } from 'utils/array.utils';

export const parseFormItemAnswer = (data: FormBuilderFormData) => {
  if (!('answer' in data)) {
    return undefined;
  }

  if (!data.answer) {
    return data.answer;
  }

  if (data.type === 'date') {
    return data.mode === 'time' ? moment(data.answer) : moment.utc(data.answer);
  }

  if (data.type === 'upload' && Array.isArray(data.answer)) {
    return mapModelToUploadFileApi(data.answer);
  }

  return data.answer;
};

interface FormItemProps {
  data: FormBuilderFormData;
  children: ReactNode;
}

const FormItem: FC<FormItemProps> = ({ data, children }) => {
  const answerRef = useRef<any>();
  const parsedAnswerRef = useRef<any>();

  if ('answer' in data && answerRef.current !== data.answer) {
    answerRef.current = data.answer;

    parsedAnswerRef.current = parseFormItemAnswer(data);
  }

  const rules: Rule[] = [];

  if (data.type === 'upload') {
    rules.push({
      validator(_, value) {
        if (!value || !Array.isArray(value) || !value.find((item) => item.status === 'uploading')) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('Wait for all uploads to finish'));
      },
    });
  }

  if (data.type === 'textEditor') {
    rules.push({
      validator(_, value) {
        if (!value || typeof value !== 'string' || !value.includes('class="image-uploading"')) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('Wait for all uploads to finish'));
      },
    });
  }

  if (data.type === 'header' || data.type === 'formGroup') {
    return <>{children}</>;
  }

  return (
    <Form.Item
      name={data.name}
      label={data.label}
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      style={{ margin: data.marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
      rules={[
        ({ getFieldValue }) => {
          if (data.submitRequired && getFieldValue('submitting')) {
            return {
              required: true,
              message: 'This field is required when submitting project brief',
            };
          }

          if (
            (!('options' in data) || data.options.length > 1) &&
            data.type !== 'upload' &&
            !data.optionalLabel &&
            getFieldValue('alert_submitting')
          ) {
            return {
              required: true,
              message: "This field is optional, but make sure you don't want to fill in",
            };
          }

          return {
            required: data.required,
            message: 'This field is required',
          };
        },
        ...rules,
      ]}
      initialValue={parsedAnswerRef.current}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
