import { FC } from 'react';
import { Input, InputProps } from 'antd';
import { FormBuilderFieldInput } from 'types/formBuilder';

interface FieldInputProps {
  id?: string;
  value?: InputProps['value'];
  data: FormBuilderFieldInput;
  disabled: boolean;
  onChange?: InputProps['onChange'];
}

const FieldInput: FC<FieldInputProps> = ({ id, value, data, disabled, onChange }) => {
  return (
    <Input
      id={id}
      value={value}
      placeholder={data.placeholder}
      type={data.inputType}
      readOnly={disabled}
      min={data.inputType === 'number' ? 0 : undefined}
      onChange={(event) => {
        if (data.inputType === 'number' && Number(event.currentTarget.value) < 0) {
          event.target.value = '0';
        }

        if (data.mask === 'currency') {
          const newValue = event.currentTarget.value.replace(/\D/g, '');

          event.target.value = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
          }).format(parseFloat(newValue || '0'));
        }

        onChange?.(event);
      }}
    />
  );
};

export default FieldInput;
