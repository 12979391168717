import { FC } from 'react';
import { Button, Popconfirm } from 'antd';

interface FormDiscardButtonProps {
  saving?: boolean;
  onDiscard: () => void;
}

const FormDiscardButton: FC<FormDiscardButtonProps> = ({ saving, onDiscard }) => {
  return (
    <Popconfirm
      title="Do you want to discard this draft?"
      placement="topRight"
      disabled={saving}
      onConfirm={() => {
        onDiscard();
      }}
    >
      <Button disabled={saving}>Discard</Button>
    </Popconfirm>
  );
};

export default FormDiscardButton;
