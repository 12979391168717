import { FC } from 'react';
import { Button, FormInstance } from 'antd';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { formScrollToFieldOnError } from 'utils/form';

export const getFormData = async (form: FormInstance, noAlert = false) => {
  try {
    const values = await form.validateFields();

    delete values.submitting;

    delete values.alert_submitting;

    Object.keys(values).forEach((key) => {
      if (key.endsWith('_force_render')) {
        delete values[key];
      }
    });

    return values;
  } catch (error) {
    if (!noAlert) {
      formScrollToFieldOnError(form, error);

      if (
        !(
          error &&
          typeof error === 'object' &&
          'errorFields' in error &&
          Array.isArray(error.errorFields) &&
          error.errorFields.length === 1 &&
          error.errorFields[0].errors[0] === 'Wait for all uploads to finish'
        )
      ) {
        asyncErrorHandler(error);
      }
    }

    throw error;
  }
};

interface FormSubmitButtonProps {
  form: FormInstance;
  saving?: boolean;
  onSubmit: (values: any) => void;
}

const FormSubmitButton: FC<FormSubmitButtonProps> = ({ form, saving, onSubmit }) => {
  return (
    <Button
      type="primary"
      disabled={saving}
      onClick={async () => {
        form.setFieldsValue({ submitting: true });

        try {
          const values = await getFormData(form);

          onSubmit(values);
        } catch (_) {
          //
        } finally {
          form.setFieldsValue({ submitting: false });
        }
      }}
    >
      Submit project brief
    </Button>
  );
};

export default FormSubmitButton;
