import { AxiosResponse } from 'axios';
import { call, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';
import apiRoutes from 'config/apiRoute';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { RootState } from 'store';

import {
  getReportUserPerformance,
  setReportUserPerformanceLoading,
  setReportUserPerformanceData,
  setReportUserPerformanceError,
  setReportUserPerformanceFilter,
  setReportUserPerformanceFilterDateRange,
  resetReportUserPerformanceFilterDropdown,
  ReportUserPerformanceState,
} from './reportUserPerformance-slice';

const { get } = api;

function* getReportUserPerformanceSagaListener() {
  const filters: ReportUserPerformanceState['filters'] = yield select((store: RootState) => store.reportUserPerformance.filters);

  try {
    yield put(setReportUserPerformanceLoading());

    const res: AxiosResponse = yield call(get, apiRoutes.USER_PERFORMANCE_REPORT, {
      date_from: filters.dateRange?.[0]?.format('YYYY-MM-DD'),
      date_to: filters.dateRange?.[1]?.format('YYYY-MM-DD'),
      'filters[user_type]': filters.userTypes,
      'filters[category]': filters.categories,
      'filters[source]': filters.source,
      'filters[project_type]': filters.projectType,
    });

    yield put(setReportUserPerformanceData(res.data.data));
  } catch (error) {
    yield put(setReportUserPerformanceError(error));
    asyncErrorHandler(error);
  }
}

function* reportUserPerformanceSaga(): Generator<StrictEffect> {
  yield takeLatest(getReportUserPerformance.type, getReportUserPerformanceSagaListener);
  yield takeLatest(setReportUserPerformanceFilter.type, getReportUserPerformanceSagaListener);
  yield takeLatest(setReportUserPerformanceFilterDateRange.type, getReportUserPerformanceSagaListener);
  yield takeLatest(resetReportUserPerformanceFilterDropdown.type, getReportUserPerformanceSagaListener);
}

export default reportUserPerformanceSaga;
