import { FC, useState } from 'react';
import { Button, Col, Form, message, Modal, Row, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import StatusTag from 'components/StatusTag';
import SelectSearch from 'components/SelectSearch';
import apiRoutes from 'config/apiRoute';
import { parseTaskTitle } from 'utils/string.utils';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import apiRequests from 'utils/api';

const { Text } = Typography;

interface RelateTaskRequestModalProps {
  uuid: string;
  projectUuid?: string;
  onClose: () => void;
  onSuccess: () => void;
}

const RelateTaskRequestModal: FC<RelateTaskRequestModalProps> = ({ uuid, projectUuid, onClose, onSuccess }) => {
  const [open, setOpen] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const confirmClickHandle = async () => {
    setSaving(true);

    try {
      const values = await form.validateFields();

      const promises = values.tasks.map((taskId: string) =>
        apiRequests.put(`${apiRoutes.PROJECT_TASKS}/${taskId}`, { add_requests: [uuid] }),
      );

      await Promise.all(promises);

      setOpen(false);

      onSuccess();

      message.success('Tasks related to the request');
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      title={<Text className="primary">Relate this request to a current task</Text>}
      visible={open}
      okText="Add"
      onOk={confirmClickHandle}
      confirmLoading={saving}
      onCancel={() => {
        setOpen(false);
      }}
      afterClose={() => {
        onClose();
      }}
    >
      <Form form={form} layout="vertical">
        <SelectSearch
          name="tasks"
          label="Task"
          labelKey="task_title"
          formItemClassName="mb-0"
          api={apiRoutes.PROJECT_TASKS}
          mode="multiple"
          noResponsive
          rules={[
            {
              required: true,
              message: 'This Field is required.',
            },
          ]}
          customValidator={(_, _value, items) => {
            return items.find((item: any) => item.requests_count)
              ? Promise.reject(new Error('This task is already related'))
              : Promise.resolve();
          }}
          defaultFilter={
            projectUuid
              ? {
                  filterBy: 'project.uuid',
                  value: projectUuid,
                }
              : undefined
          }
          searchKey="search_term[0][0][value]"
          defaultParams={{
            'filters[task_status]': [
              'todo',
              'in_progress',
              'waiting_approval',
              'working_on_now',
              'pending',
              'approved',
              'needs_attention',
            ],
            with: 'requests_count',
            'search_term[0][0][value]': '',
            'search_term[0][0][field]': '*',
            'search_term[0][0][rule]': 'contains',
          }}
          tagRender={({ item, label, onClose: onCloseSelected }) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0px 4px 0px 8px',
                  margin: '2px 4px 2px 0px',
                  background: '#f5f5f5',
                  border: '1px solid',
                  borderColor: item.requests_count ? '#ff4d4f' : '#f0f0f0',
                  borderRadius: '2px',
                }}
              >
                {label}
                <Button
                  type="link"
                  icon={<CloseOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 10 }} />}
                  size="small"
                  style={{ height: '22px' }}
                  onClick={onCloseSelected}
                />
              </div>
            );
          }}
          render={(task: any) => (
            <Row wrap={false} justify="space-between" align="middle" gutter={[16, 0]} style={{ paddingRight: 8, maxWidth: 380 }}>
              <Col flex="auto">
                <Text style={{ color: 'inherit' }} ellipsis>
                  {parseTaskTitle(task)}
                </Text>
              </Col>
              <Col flex="none">
                <StatusTag value={task.task_status} size="small" />
              </Col>
            </Row>
          )}
        />
      </Form>
    </Modal>
  );
};

export default RelateTaskRequestModal;
