import { FC } from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import SelectSearch from 'components/SelectSearch';
import apiRoutes from 'config/apiRoute';

interface CreatedByFilterProps {
  handleSetFilter: any;
  filter: any;
  filterBy?: string;
  form: any;
}

const CreatedByFilter: FC<CreatedByFilterProps> = ({ filter, filterBy = 'creator.uuid', handleSetFilter, form }) => {
  const user = useSelector((store: any) => store.auth.user);
  const warpFilter = filter ?? [];
  const filterUsers = warpFilter?.filter((el: any) => el.isCreators);

  return (
    <Form form={form} initialValues={{ created_by: filterUsers?.map((el: any) => el.uuid) }}>
      <SelectSearch
        api={apiRoutes.CONTACTS}
        formItemClassName="mb-0"
        initialOptions={filterUsers}
        defaultParams={{ 'filters[billing_contacts.status][]': 'active' }}
        mode="multiple"
        name="created_by"
        valueKey="uuid"
        searchKey="search_term"
        searchByLabelKey
        onChange={(values: any) => {
          const uniqueUsers: any[] = [];
          const temp: any = {};
          values.forEach((el: any) => {
            const id = el.uuid === 'myTasks' ? user?.uuid : el.user?.uuid ?? el.uuid;

            if (!temp[id]) {
              uniqueUsers.push(el);
              temp[id] = true;
            }
          });

          handleSetFilter([
            ...warpFilter?.filter((el: any) => !el.isCreators),
            ...uniqueUsers?.map((el: any) => ({
              ...el,
              filterBy,
              value: el.uuid === 'myTasks' ? user?.uuid : el.user?.uuid ?? el.uuid,
              isCreators: true,
            })),
          ]);
        }}
        placeholder="Created by"
        startingOptions={[{ name: 'My tasks', uuid: 'myTasks' }]}
        style={{ width: '100%', minWidth: '150px', textAlign: 'left' }}
        size="large"
        allowClear
      />
    </Form>
  );
};

export default CreatedByFilter;
