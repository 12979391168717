import { FC, ReactNode } from 'react';

const mapURLRequestIcons: Record<number, ReactNode> = {
  1: 'ArtistPalette',
  2: 'BarChart',
  3: 'OrangeBook',
  6: 'Package',
  7: 'Handshake',
  8: 'Email',
  9: 'Pencil',
  10: 'DesktopComputer',
  11: 'MobilePhoneWithArrow',
  12: 'TearOffCalendar',
  13: 'ClapperBoard',
  14: 'VideoCam',
  15: 'Laptop',
  16: 'Cyclone',
  17: 'LoudSpeaker',
  18: 'MoneyBag',
};

interface RequestIconProps {
  formId: string | number;
}

const RequestIcon: FC<RequestIconProps> = ({ formId }) => {
  return (
    <img
      alt="request-icon"
      src={`https://savage-cdn.s3.us-west-2.amazonaws.com/request-icons/${
        mapURLRequestIcons[formId as keyof typeof mapURLRequestIcons]
      }.svg`}
      style={{ width: 24 }}
    />
  );
};

export default RequestIcon;
