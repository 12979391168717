import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, DatePicker, Divider, Empty, Form, Modal, PageHeader, Row, Select, Space, Tag, Typography } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { FaRegCopy } from 'react-icons/fa';
import { BsExclamationOctagon } from 'react-icons/bs';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import ProjectSelect from 'modules/project/components/ProjectSelect';
import SelectUserAvatar from 'components/SelectUserAvatar';
import SelectResponsible from 'components/TaskModal/components/SelectResponsible';
import SelectFollowers from 'components/TaskModal/components/SelectFollowers';
import RequestTimerDropdown from 'components/Timer/RequestTimerDropdown';
import FormBuilderAnswers from 'components/FormBuilder/FormBuilderAnswers';
import FieldTimeRangeCustom from 'components/FieldTimeRangeCustom';
import FormBuilderWizardForm from 'components/FormBuilder/FormBuilderWizardForm';
import FormItem, { parseFormItemAnswer } from 'components/FormBuilder/FormItem';
import FieldUpload from 'components/FormBuilder/fields/FieldUpload';
import FormButtons from 'components/FormBuilder/FormButtons';
import { getFormData } from 'components/FormBuilder/buttons/FormSubmitButton';
import { convertHrsMinsToMins, convertMinsToHrsMins, getStatusColor, parseDateToUSFormat, renderTime } from 'utils/string.utils';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { debounceAsync } from 'utils/debounce';
import { formScrollToFieldOnError } from 'utils/form';
import { mapModelToUploadFileApi } from 'utils/array.utils';
import {
  FormBuilderData,
  FormBuilderFieldDate,
  FormBuilderFieldInput,
  FormBuilderFieldRadio,
  FormBuilderFieldUpload,
  FormBuilderFormData,
} from 'types/formBuilder';
import { UploadFileApi } from 'types/upload-type';
import appRoutes from 'config/appRoutes';
import ENVIRONMENT from 'config/environment';
import { UserResponse } from 'types';
import { getRequestStatusOptions } from '../../config/getRequestStatusOptions';
import listFormsData from '../../config/listForms.json';
import style from './index.module.less';

const { Text } = Typography;

const listForms = listFormsData as FormBuilderData[];

interface FixedFields {
  subject: FormBuilderFieldInput;
  deadline: FormBuilderFieldDate;
  skipEstimateApproval: FormBuilderFieldRadio;
  kickOffCall: FormBuilderFieldRadio;
  uploads: FormBuilderFieldUpload;
}

const fixedFields: FixedFields = {
  subject: {
    name: 'subject',
    label: 'Project name',
    type: 'input',
    submitRequired: true,
    placeholder: 'Give your project a name',
  },
  deadline: {
    name: 'target_date',
    label: 'Deadline',
    type: 'date',
    submitRequired: true,
    mode: 'simple',
  },
  skipEstimateApproval: {
    name: 'skip_estimate_approval',
    label: 'Skip estimate approval?',
    type: 'radio',
    mode: 'pill',
    optionalLabel: true,
    options: [
      {
        label: "Yes, I'd like to skip the estimate approval and start the project right away.",
        value: true,
      },
    ],
  },
  kickOffCall: {
    name: 'kickoff_call',
    label: 'Would you like to message us or call before we get started?',
    type: 'radio',
    mode: 'pill',
    optionalLabel: true,
    options: [
      {
        label: 'Yes (Message)',
        value: 'message',
      },
      {
        label: 'Yes (Call)',
        value: 'call',
      },
    ],
  },
  uploads: {
    name: 'uploads',
    label: 'Upload any assets related to this project (optional)',
    type: 'upload',
  },
};

const parseFormBuilderAnswers = (answers: Record<string, any>, data: FormBuilderFormData[]) => {
  return data.reduce((acc, item) => {
    if (item.type === 'formGroup') {
      const formGroup = listForms.find((form) => form.id === item.formId);

      if (formGroup?.form) {
        acc.push({ ...item, fields: parseFormBuilderAnswers(answers, formGroup.form) });
      }
    }

    if ('name' in item) {
      let answer = answers[item.name];

      if (item.type === 'upload' && Array.isArray(answer)) {
        answer = answer
          .filter((file: UploadFileApi) => file.response && !file.response.stated)
          .map((file: UploadFileApi) => file.response?.uuid);
      }

      if (item.type === 'textEditor' && answer) {
        answer = answer.replaceAll(
          `src="${ENVIRONMENT.REACT_APP_UPLOADS_PATH.replace('uploads', 'tmp')}/`,
          `src="${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/`,
        );
      }

      acc.push({ ...item, answer });
    }

    return acc;
  }, [] as FormBuilderFormData[]);
};

const getRequestFormData = ({
  type,
  request,
  formData,
}: {
  type: RequestFormProps['type'];
  request: RequestFormProps['request'];
  formData?: FormBuilderFormData[];
}): FormBuilderFormData[] | undefined => {
  if (!formData) return undefined;

  return [
    ...(type === 'create' || type === 'draft'
      ? [
          {
            ...fixedFields.subject,
            answer: request?.[fixedFields.subject.name],
          },
        ]
      : []),
    ...formData,
    {
      ...fixedFields.deadline,
      answer: request?.[fixedFields.deadline.name],
    },
    {
      ...fixedFields.skipEstimateApproval,
      answer: request?.[fixedFields.skipEstimateApproval.name],
    },
    {
      ...fixedFields.kickOffCall,
      answer: request?.[fixedFields.kickOffCall.name],
    },
  ];
};

const debounceUpdate = debounceAsync((onUpdate: () => Promise<void>) => {
  return onUpdate();
}, 600);

export interface RequestFormProps {
  type: 'create' | 'view' | 'draft';
  request?: Record<string, any>;
  formBuilder?: FormBuilderData;
  listFormBuilder?: FormBuilderData[];
  projectUuid?: string;
  pageUrl: string;
  classNameColFormQuestions?: string;
  resetForm?: {
    oldRequest: Record<string, any>;
    oldFormBuilder: FormBuilderData;
  };
  goBack?: boolean;
  disableFormAnswers?: boolean;
  onDiscard?: () => Promise<void>;
  onDraft?: (values: any) => Promise<Record<string, any>>;
  onSubmit?: (values: any) => Promise<Record<string, any>>;
  onUpdate?: (values: any) => Promise<Record<string, any>>;
  onValidateStatusComplete?: (formValues: Record<string, any>) => { label: string; validate: boolean }[] | boolean;
  onAddedTimelog?: (timelog: any) => void;
}

const RequestForm: FC<RequestFormProps> = ({
  type,
  request,
  formBuilder,
  listFormBuilder,
  projectUuid,
  pageUrl,
  classNameColFormQuestions = style.colFormQuestions,
  resetForm,
  goBack = false,
  disableFormAnswers: propDisableFormAnswers = false,
  onDiscard,
  onDraft,
  onSubmit,
  onValidateStatusComplete,
  onUpdate,
  onAddedTimelog,
}) => {
  const user: UserResponse = useSelector((store: any) => store.auth.user);
  const [saving, setSaving] = useState(false);
  const [editingNoDraft, setEditingNoDraft] = useState(false);
  const [statusValidationFailed, setStatusValidationFailed] = useState<{ label: string; validate: boolean }[]>();
  const [prevStatus, setPrevStatus] = useState<string | undefined>(request?.status);
  const [selectedProjectUuid, setSelectedProjectUuid] = useState(projectUuid ?? request?.project?.uuid);
  const [formAnswerValues, setFormAnswerValues] = useState<any>(() => {
    if (!formBuilder) return undefined;

    const reduceFn = (acc: Record<string, any>, item: FormBuilderFormData) =>
      'name' in item ? { ...acc, [item.name]: item.answer } : acc;

    if (formBuilder.form) {
      return formBuilder.form.reduce(reduceFn, {});
    }

    if (formBuilder.wizardForm) {
      return formBuilder.wizardForm.reduce((acc, wizardForm) => {
        return { ...acc, ...wizardForm.form.reduce(reduceFn, {}) };
      }, {});
    }

    return undefined;
  });
  const [relatedUsers, setRelatedUsers] = useState(() => {
    const responsibles = request?.related_users?.filter((el: any) => el.pivot.type === 'responsible') ?? [];
    const followers = request?.related_users?.filter((el: any) => el.pivot.type === 'follower') ?? [];

    return { responsibles, followers };
  });

  const history = useHistory();

  const [formMain] = Form.useForm();
  const [formAnswer] = Form.useForm();

  const isFreelancer = user.contact.type === 'freelancer';

  const disableFormAnswers = (propDisableFormAnswers && !editingNoDraft) || isFreelancer;

  const formData = useMemo(() => {
    return getRequestFormData({ type: editingNoDraft ? 'draft' : type, request, formData: formBuilder?.form });
  }, [formBuilder?.form, request, type, editingNoDraft]);

  const parseDataToSave = (mainValues: Record<string, any>, answerValues?: Record<string, any>) => {
    if (answerValues) {
      Object.values(fixedFields).forEach((field) => {
        if (field.name in answerValues) {
          mainValues[field.name] = answerValues[field.name];

          delete answerValues[field.name];
        }
      });
    }

    if (formBuilder && answerValues) {
      mainValues.description = JSON.stringify({
        ...formBuilder,
        form: formBuilder.form && parseFormBuilderAnswers(answerValues, formBuilder.form),
        wizardForm:
          formBuilder.wizardForm &&
          formBuilder.wizardForm.map((item) => ({ ...item, form: parseFormBuilderAnswers(answerValues, item.form) })),
      });

      mainValues.title = formBuilder.name;
    }

    mainValues.skip_estimate_approval = mainValues.skip_estimate_approval ?? false;

    if ('kickoff_call' in mainValues) {
      mainValues.kickoff_call = mainValues.kickoff_call ?? null;
    }

    if ('subject' in mainValues) {
      mainValues.subject = mainValues.subject || 'Draft';
    }

    if ('estimated_time_range' in mainValues) {
      if (mainValues.estimated_time_range) {
        const [hourMin, hourMax] = mainValues.estimated_time_range.split('-');

        mainValues.estimated_min = hourMin ? convertHrsMinsToMins(hourMin) : 0;
        mainValues.estimated_max = hourMax ? convertHrsMinsToMins(hourMax) : 0;
      } else {
        mainValues.estimated_min = 0;
        mainValues.estimated_max = 0;
      }

      delete mainValues.estimated_time_range;
    }

    if ('completed_at' in mainValues && mainValues.completed_at) {
      mainValues.completed_at = mainValues.completed_at.format('YYYY-MM-DD');
    }

    if ('target_date' in mainValues) {
      mainValues.target_date = mainValues.target_date && moment(mainValues.target_date).format('YYYY-MM-DD');
    }

    if ('uploads' in mainValues && Array.isArray(mainValues.uploads)) {
      mainValues.uploads = mainValues.uploads
        .filter((file: UploadFileApi) => file.response?.stated === false)
        .map((file: UploadFileApi) => file.response?.uuid);
    }

    if ('responsibles' in mainValues) {
      mainValues.responsibles = mainValues.responsibles ? [mainValues.responsibles] : [];
    }

    return mainValues;
  };

  const onWrapDraft = async (answerValues: any) => {
    setSaving(true);

    try {
      const values = await formMain.validateFields();

      values.status = 'draft';

      await onDraft?.(parseDataToSave(values, answerValues));
    } catch (error) {
      formScrollToFieldOnError(formMain, error);
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const onWrapSubmit = async (answerValues: any) => {
    setSaving(true);

    if (type !== 'create') {
      delete answerValues.uploads;
    }

    try {
      const values = await formMain.validateFields();

      values.status = 'new';

      await onSubmit?.(parseDataToSave(values, answerValues));
    } catch (error) {
      formScrollToFieldOnError(formMain, error);
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const onWrapUpdate = (showCancelButton = false) => {
    if (!onUpdate) return;

    setSaving(true);

    debounceUpdate(async () => {
      try {
        const mainValues = await formMain.validateFields();
        const answerValues = await getFormData(formAnswer);

        delete mainValues.status;

        if (!editingNoDraft) {
          delete answerValues.uploads;
        }

        const newValues = parseDataToSave(mainValues, answerValues);

        try {
          await onUpdate(newValues);

          if (newValues.uploads) {
            const currentUploads = formAnswer.getFieldValue('uploads');

            const tes = currentUploads.map((upload: UploadFileApi) => {
              if (upload.response && newValues.uploads.includes(upload.response.uuid)) {
                upload.response.stated = true;
                upload.status = 'done';
              }

              return { ...upload };
            });

            formAnswer.setFieldsValue({
              uploads: tes,
            });
          }

          if (editingNoDraft && showCancelButton) {
            setEditingNoDraft(false);
          }
        } catch (error) {
          asyncErrorHandler(error);
        }
      } finally {
        setSaving(false);
      }
    });
  };

  const onUpdateStatus = async (newStatus: string, force = false) => {
    if (!onUpdate) return;

    if (newStatus === 'completed' && !force && onValidateStatusComplete && request) {
      const validate = onValidateStatusComplete(parseDataToSave(formMain.getFieldsValue()));

      if (validate !== true) {
        formMain.setFieldsValue({ status: prevStatus });

        if (typeof validate === 'object') {
          setStatusValidationFailed(validate);
        }
        return;
      }
    }

    setSaving(true);

    setPrevStatus(newStatus);

    try {
      await onUpdate({ status: newStatus });
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const onWrapDiscard = async () => {
    setSaving(true);

    try {
      await onDiscard?.();
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    const getNotDirtyKeys = (currentValues: Record<string, any>, initialValues: Record<string, any>) => {
      return Object.keys(currentValues).filter((key) => isEqual(currentValues[key], initialValues[key]));
    };

    if (resetForm) {
      formMain.resetFields(
        getNotDirtyKeys(formMain.getFieldsValue(), {
          ...resetForm.oldRequest,
          responsibles: resetForm.oldRequest.related_users.find((el: any) => el.pivot.type === 'responsible')?.uuid,
          followers: resetForm.oldRequest.related_users
            .filter((el: any) => el.pivot.type === 'follower')
            .map((el: any) => el.uuid),
        }),
      );

      const oldFormData = getRequestFormData({
        type: 'draft',
        request: resetForm.oldRequest,
        formData: resetForm.oldFormBuilder?.form,
      });

      if (oldFormData) {
        const mapAnswers = oldFormData.reduce((acc, item) => {
          if ('name' in item) {
            return {
              ...acc,
              [item.name]: parseFormItemAnswer(item),
            };
          }

          return acc;
        }, {} as Record<string, any>);

        const fieldsValue = formAnswer.getFieldsValue();

        const uploadValue = fieldsValue.uploads ?? [];

        delete fieldsValue.uploads;

        formAnswer.resetFields(getNotDirtyKeys(fieldsValue, mapAnswers));

        formAnswer.setFieldsValue({
          uploads: [
            ...uploadValue,
            ...mapModelToUploadFileApi(
              (request?.uploads ?? []).filter(
                (item: any) => !uploadValue.find((file: UploadFileApi) => file.response?.uuid === item.uuid),
              ),
            ),
          ],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, formAnswer, formMain, resetForm]);

  useLayoutEffect(() => {
    if (request?.status === 'completed') {
      formMain.resetFields(['completed_at']);
    }
  }, [formMain, request?.status]);

  useLayoutEffect(() => {
    if (request?.related_users) {
      const responsibles = request.related_users.filter((el: any) => el.pivot.type === 'responsible');
      const followers = request.related_users.filter((el: any) => el.pivot.type === 'follower');

      setRelatedUsers({ responsibles, followers });
    }
  }, [request?.related_users]);

  return (
    <Row className="flex-col h-full" wrap={false}>
      <Modal
        visible={statusValidationFailed !== undefined}
        className="ant-modal-confirm"
        width={430}
        okText="Complete anyway"
        cancelText="Go back and edit"
        onOk={() => {
          formMain.setFieldsValue({ status: 'completed' });
          setStatusValidationFailed(undefined);
          onUpdateStatus('completed', true);
        }}
        onCancel={() => {
          setStatusValidationFailed(undefined);
        }}
        destroyOnClose
      >
        <div className="ant-modal-confirm-body">
          <BsExclamationOctagon className="danger anticon" style={{ fontSize: 20 }} />

          <span className="ant-modal-confirm-title">Ooops, there is something wrong!</span>

          <div className="ant-modal-confirm-content">
            {statusValidationFailed?.map((item) => (
              <div key={item.label} style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 6, fontSize: 18 }}>
                  {item.validate ? <CheckCircleFilled className="green" /> : <CloseCircleFilled className="danger" />}
                </span>
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <Col flex="none">
        <Form
          form={formMain}
          initialValues={{
            ...request,
            project_id: selectedProjectUuid,
            user_id: request?.requester?.uuid,
            completed_at: request?.completed_at && moment.utc(request?.completed_at),
            responsibles: request?.related_users?.find((el: any) => el.pivot.type === 'responsible')?.uuid,
            followers: request?.related_users?.filter((el: any) => el.pivot.type === 'follower').map((el: any) => el.uuid),
          }}
          labelAlign="left"
          layout="horizontal"
          labelCol={{ style: { width: 110, flexShrink: 0 } }}
          onValuesChange={(changedValues) => {
            if ('status' in changedValues && Object.keys(changedValues).length === 1) {
              return;
            }

            onWrapUpdate();
          }}
        >
          <Row align="middle" gutter={[12, 0]}>
            <Col flex="auto">
              {goBack && (
                <PageHeader
                  title={request?.title ?? 'Go back'}
                  className="primary"
                  style={{ padding: 0, marginBottom: '0.8rem' }}
                  onBack={() => history.push(pageUrl)}
                  extra={saving && <LoadingOutlined style={{ fontSize: 22, marginTop: 4 }} spin />}
                />
              )}
            </Col>

            {type !== 'create' ? (
              <Col>
                <Space size={14}>
                  {type === 'view' && (
                    <Form.Item label="Status" name="status" className="mb-s" labelCol={{ style: { flexShrink: 0 } }}>
                      <Select
                        style={{ minWidth: 140 }}
                        disabled={isFreelancer}
                        options={getRequestStatusOptions(false).map((item) => ({
                          ...item,
                          display: item.label,
                          label: (
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                              <Tag style={{ marginRight: 0 }} color={getStatusColor(item.value)}>
                                {item.label}
                              </Tag>
                            </div>
                          ),
                        }))}
                        onChange={(item) => {
                          onUpdateStatus(item as string);
                        }}
                      />
                    </Form.Item>
                  )}

                  {!isFreelancer && (
                    <Link to={`${appRoutes.REQUESTS}/${request?.uuid}/duplicate`}>
                      <FaRegCopy />
                    </Link>
                  )}
                </Space>
              </Col>
            ) : undefined}
          </Row>

          {type !== 'create' ? (
            !projectUuid && (
              <Row gutter={[8, 8]}>
                <Col flex="auto">
                  <Form.Item label="Client" name="user_id" className="mb-s">
                    <Text type="secondary">{request?.project?.customer?.business_name}</Text>
                  </Form.Item>
                </Col>

                {!isFreelancer && (
                  <Col>
                    <Link to={`${appRoutes.PROJECTS}/${selectedProjectUuid}/requests`}>Go to project</Link>
                  </Col>
                )}
              </Row>
            )
          ) : (
            <Form.Item
              label="Project"
              name="project_id"
              className="mb-s"
              hidden={!!projectUuid}
              rules={[{ required: true, message: 'Please enter the project' }]}
            >
              <ProjectSelect
                onlyRetainer
                onlyActive
                onChange={(value) => {
                  setSelectedProjectUuid(value);
                  formMain.setFieldsValue({ user_id: undefined });
                }}
              />
            </Form.Item>
          )}

          <Row align="middle" gutter={[12, 0]} justify="space-between">
            <Col flex="auto">
              <Form.Item
                label="Requested by "
                name="user_id"
                className="mb-s"
                rules={type === 'create' ? [{ required: true, message: 'Please enter the requester' }] : undefined}
              >
                {type !== 'create' ? (
                  <Text type="secondary">
                    {request?.requester?.name ?? 'No one'} at {parseDateToUSFormat(request?.created_at, true)}
                  </Text>
                ) : (
                  <SelectUserAvatar
                    placeholder={selectedProjectUuid ? 'Select a requester' : 'Select a project first'}
                    defaultOptions={
                      request?.requester
                        ? [
                            {
                              key: request.requester.uuid,
                              value: request.requester.uuid,
                              label: request.requester.name,
                              user: request.requester,
                            },
                          ]
                        : undefined
                    }
                    size="middle"
                    projectId={selectedProjectUuid}
                    disabled={!selectedProjectUuid}
                    bordered
                  />
                )}
              </Form.Item>
            </Col>

            {request?.status === 'completed' && (
              <Col>
                <Form.Item label="Completed at" name="completed_at" className="mb-s" labelCol={{ style: { flexShrink: 0 } }}>
                  <DatePicker format="MM/DD/YYYY" allowClear={false} disabled={isFreelancer} />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={[12, 0]}>
            <Col flex="auto" className="mb-s w-full lg-flex-1">
              <SelectResponsible
                label="Responsible"
                form={formMain}
                state={relatedUsers}
                finalProjectId={selectedProjectUuid}
                setState={setRelatedUsers}
                disabled={!selectedProjectUuid || isFreelancer}
                bordered
              />
            </Col>

            <Col flex="auto" className="mb-s w-full lg-flex-1" style={{ minWidth: 190 }}>
              <SelectFollowers
                label="Followers"
                form={formMain}
                state={relatedUsers}
                finalProjectId={selectedProjectUuid}
                setState={setRelatedUsers}
                labelCol={{ style: { width: 110, flexShrink: 0 }, className: 'lg-w-auto' }}
                disabled={!selectedProjectUuid || isFreelancer}
                bordered
              />
            </Col>
          </Row>

          <Row align="middle" gutter={[12, 0]}>
            <Col flex="auto" className="w-full md-w-auto" style={{ minWidth: 265 }}>
              <FieldTimeRangeCustom name="estimated_time_range" label="Estimated time" className="mb-s" disabled={isFreelancer} />
            </Col>

            {type === 'view' && request ? (
              <Col flex="none">
                <Form.Item label="Spent" className="mb-s" labelCol={{ style: { flexShrink: 0 } }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text type="secondary">{renderTime(convertMinsToHrsMins(request.time_spent))}</Text>

                    {!isFreelancer && <RequestTimerDropdown request={request} onAdded={onAddedTimelog} />}
                  </div>
                </Form.Item>
              </Col>
            ) : undefined}

            {type !== 'create' ? (
              <Col flex="none">
                <Form.Item label="Due date" className="mb-s" labelCol={{ style: { flexShrink: 0 } }}>
                  <Text type="secondary">
                    {request?.target_date ? parseDateToUSFormat(request.target_date, false, true) : 'no due date'}
                  </Text>
                </Form.Item>
              </Col>
            ) : undefined}
          </Row>

          {type === 'view' && (
            <Form.Item label="Project name" style={{ alignItems: 'start' }} className="mb-m">
              <Text style={{ wordBreak: 'break-word' }} strong>
                {request?.subject}
              </Text>
            </Form.Item>
          )}
        </Form>

        <Divider style={{ margin: '6px 0' }} className="primary" />
      </Col>

      {type === 'view' && !isFreelancer && (
        <Col flex="none" style={{ textAlign: 'right' }}>
          {editingNoDraft && (
            <Button
              className="mb-s mr-m"
              disabled={saving}
              onClick={() => {
                formAnswer.resetFields();
                setEditingNoDraft(false);
              }}
            >
              Cancel
            </Button>
          )}

          <Button
            type={editingNoDraft ? 'primary' : undefined}
            className="mb-s"
            loading={editingNoDraft && saving}
            onClick={editingNoDraft ? () => onWrapUpdate(true) : () => setEditingNoDraft(true)}
          >
            {editingNoDraft ? 'Save' : 'Edit'}
          </Button>
        </Col>
      )}

      <Col className={classNameColFormQuestions}>
        <div style={{ padding: '0px 4px' }}>
          {formBuilder ? (
            <Form
              form={formAnswer}
              layout="vertical"
              colon={false}
              requiredMark={false}
              labelCol={{ style: { fontWeight: '600' } }}
              onValuesChange={(changedValues, newValues) => {
                setFormAnswerValues(newValues);

                const keys = Object.keys(changedValues);

                if ((keys.length > 1 || keys[0] !== 'uploads') && formAnswerValues && !editingNoDraft) {
                  onWrapUpdate();
                }
              }}
            >
              {formBuilder.wizardForm && (
                <FormBuilderWizardForm
                  form={formAnswer}
                  data={formBuilder.wizardForm}
                  formValues={formAnswerValues}
                  disabled={disableFormAnswers}
                />
              )}

              {formData ? (
                <>
                  <FormBuilderAnswers
                    form={formAnswer}
                    data={formData}
                    formValues={formAnswerValues}
                    listData={listFormBuilder}
                    disabled={disableFormAnswers}
                    onTextImageUpload={
                      onUpdate && !editingNoDraft
                        ? async (upload) => {
                            if (!upload.response) return undefined;

                            const data = await onUpdate?.({
                              uploads: [upload.response.uuid],
                            });

                            if (data && upload.url) {
                              upload.url = upload.url.replace('/tmp/', '/uploads/');

                              if (upload.response) {
                                upload.response.url = upload.url;
                                upload.response.stated = true;
                              }

                              const currentUploads = formAnswer.getFieldValue('uploads');

                              formAnswer.setFieldsValue({
                                uploads: currentUploads ? [...currentUploads, upload] : [upload],
                              });

                              return upload.url;
                            }

                            return undefined;
                          }
                        : undefined
                    }
                  />

                  <FormItem data={{ ...fixedFields.uploads, answer: request?.uploads }}>
                    <FieldUpload
                      data={fixedFields.uploads}
                      form={formAnswer}
                      resource="request"
                      resourceIds={request ? [request.uuid] : undefined}
                      disabled={disableFormAnswers}
                      showDownloadIcon={!!onUpdate}
                      onRemove={(_, textEditorUpdated) => textEditorUpdated && onWrapUpdate()}
                      onRequest={
                        !editingNoDraft && onUpdate
                          ? async (upload) => {
                              try {
                                return await onUpdate({ uploads: [upload.uuid] });
                              } catch (error) {
                                asyncErrorHandler(error);
                              }

                              return null;
                            }
                          : undefined
                      }
                    />
                  </FormItem>

                  {!isFreelancer && (
                    <FormButtons
                      form={formAnswer}
                      saving={saving}
                      onDraft={onDraft && onWrapDraft}
                      onSubmit={onSubmit && onWrapSubmit}
                      onDiscard={onDiscard && onWrapDiscard}
                    />
                  )}
                </>
              ) : undefined}
            </Form>
          ) : (
            <Empty description="No form request" />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default RequestForm;
