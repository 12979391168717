import { FC, useState } from 'react';
import { Button, Dropdown, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TbClock } from 'react-icons/tb';
import moment from 'moment';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { convertHrsMinsToMins, localRandomUuid } from 'utils/string.utils';
import TimerContainer from './TimerContainer';
import { requestTimelogSlice } from '../../modules/request/store/requestTimelog';
import { setGlobalLogTrigger } from '../../store/container/globalLog/globalLog-slice';

interface RequestTimerDropdownProps {
  request: Record<string, any>;
  onAdded?: (timelog: any) => void;
}

const RequestTimerDropdown: FC<RequestTimerDropdownProps> = ({ request, onAdded }) => {
  const user = useSelector((store: any) => store.auth.user);

  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const onAddTimeLog = async () => {
    setSaving(true);

    try {
      const formData = await form.validateFields();

      const { data } = await apiRequests.post(`${apiRoutes.REQUEST_TIME_LOG}`, {
        request_id: request.uuid,
        user_id: formData.user_id ?? user.uuid,
        date: moment(formData.date).format('YYYY-MM-DD'),
        minutes: convertHrsMinsToMins(formData.time),
        description: formData.description,
        ref_token: localRandomUuid,
      });

      form.resetFields();

      setVisible(false);

      dispatch(requestTimelogSlice.actions.fetch());

      dispatch(setGlobalLogTrigger());

      onAdded?.(data.data);
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      {visible && (
        <div
          style={{
            height: '100vh',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 100,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setVisible(false);
          }}
        />
      )}

      <Dropdown
        visible={visible}
        placement="bottomCenter"
        overlay={
          <TimerContainer
            style={{ width: 480 }}
            form={form}
            projectId={request.project.uuid}
            modalStatus={request.status}
            loading={saving}
            showUserSelection="request"
            onAddEntry={onAddTimeLog}
            onCancel={() => {
              setVisible(false);
            }}
          />
        }
      >
        <Button type="link" style={{ height: 28 }} icon={<TbClock />} onClick={() => setVisible(true)} />
      </Dropdown>
    </>
  );
};

export default RequestTimerDropdown;
