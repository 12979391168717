import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getCustomerSelect,
  setCustomerSelectData,
  setCustomerSelectError,
  setCustomerSelectLoading,
} from './customerSelect-slice';

const { get } = api;

function* getCustomerSelectSagaListener(action: any) {
  try {
    yield put(setCustomerSelectLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.CUSTOMERS, {
      'page[size]': 999,
      'filters[status][]': 'active',
      ...(action.payload ?? {}),
    });
    yield put(setCustomerSelectData(res.data));
  } catch (error) {
    yield put(setCustomerSelectError(error));
    asyncErrorHandler(error);
  }
}

function* customerSaga(): Generator<StrictEffect> {
  yield takeEvery(getCustomerSelect.type, getCustomerSelectSagaListener);
}

export default customerSaga;
