import { FC, useRef } from 'react';
import { Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AppTable, { appTableStyles } from 'components/AppTable';
import { AppTableColumn } from 'components/AppTable/useColumns';
import EditTimerDropdown from 'components/Timer/EditTimerDropdown';
import DeleteTimer from 'components/Timer/DeleteTimer';
import { convertMinsToHrsMins, ellipseText, localRandomUuid, parseDateToUSFormat, renderTime } from 'utils/string.utils';
import useWebSocket from 'utils/useWebSocket';
import { UserResponse } from 'types';
import { RootState } from 'store';
import { requestTimelogSlice } from '../../store/requestTimelog';

const { Text } = Typography;

interface RequestTimelogTabProps {
  uuid: string;
}

const RequestTimelogTab: FC<RequestTimelogTabProps> = ({ uuid }) => {
  const user: UserResponse = useSelector((store: any) => store.auth.user);
  const totalTime = useSelector((globalState: RootState) => globalState.requestTimelog.totalTime);
  const isMountedRef = useRef(false);

  const isFreelancer = user.contact.type === 'freelancer';

  const dispatch = useDispatch();

  if (!isMountedRef.current) {
    isMountedRef.current = true;
    dispatch(requestTimelogSlice.actions.restoreInitialState());
    dispatch(
      requestTimelogSlice.actions.setFixedParams({
        'filters[source_uuid][]': uuid,
      }),
    );
  }

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.requesttimelog',
      callback: (event: any) => {
        if (event.payload.ref_token === localRandomUuid) return;

        if (event.payload.request_id === uuid) {
          dispatch(requestTimelogSlice.actions.fetch({ silenceLoading: true }));
        }
      },
    },
  });

  const columns: AppTableColumn[] = [
    {
      title: 'Tracked',
      dataIndex: 'minutes',
      width: 100,
      exportRender: true,
      sorter: true,
      render: (value) => renderTime(convertMinsToHrsMins(value)),
    },

    {
      title: 'Date',
      dataIndex: 'date',
      exportRender: true,
      sorter: true,
      render: (value) => parseDateToUSFormat(value),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      minSpace: true,
      sorter: true,
      minSpaceText: (value) => ellipseText(value),
      render: (value) => ellipseText(value),
    },
    {
      title: 'User',
      dataIndex: 'user_name',
      sorter: true,
      render: (value) => <Text className="primary">{value}</Text>,
    },
    ...(!isFreelancer
      ? [
          {
            title: 'Actions',
            dataIndex: 'actions',
            hideFromExport: true,
            fixed: 'right' as const,
            render: (_: any, row: any) => {
              return (
                <Space size={2}>
                  <EditTimerDropdown
                    timelog={row}
                    modelType={row.source}
                    placement="topLeft"
                    showUserSelection
                    onUpdate={() => dispatch(requestTimelogSlice.actions.fetch())}
                  />
                  <DeleteTimer
                    uuid={row.uuid}
                    modelType={row.source}
                    onDelete={() => dispatch(requestTimelogSlice.actions.fetch())}
                  />
                </Space>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <div className={`${appTableStyles.container} ${appTableStyles.fullPage}`} style={{ padding: 0 }}>
      <AppTable
        storeSlice={requestTimelogSlice}
        prefixPagination={totalTime ? `${totalTime} / ` : undefined}
        containerStyle={{ marginTop: 0 }}
        textCount="timelogs found"
        columns={columns}
        fullPage
        hideRowSelection
        hideActionColumn
      />
    </div>
  );
};

export default RequestTimelogTab;
