import { createAppTableSaga, createAppTableStore, createPersist } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';

export const projectSlice = createAppTableStore({
  name: 'tableProjects' as const,
  url: apiRoutes.PROJECTS_LITE,
  initialState: {
    params: {
      filters: {
        'projects.status': [{ value: 'paused' }, { value: 'waiting_to_launch' }, { value: 'in_progress' }, { value: 'active' }],
      },
      sort: { column: 'title', order: 'ascend' },
    },
    updateUrl: apiRoutes.PROJECTS,
  },
});

export const projectsSaga = createAppTableSaga(projectSlice);

export const projectsReducer = createPersist(projectSlice);
