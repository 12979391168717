import { FC } from 'react';
import { Col, Row, Space } from 'antd';
import ProgressBarDouble from 'components/ProgressBarDouble';
import { convertMinsToHrsMins, renderTime } from 'utils/string.utils';

interface TimeReportProgressProps {
  report?: Record<string, any>;
  infoSameLine?: 'md';
}

const TimeReportProgress: FC<TimeReportProgressProps> = ({ report, infoSameLine }) => {
  const available = report?.available;
  const availableString = convertMinsToHrsMins(available);

  const spentPercent = report ? (report.spent * 100) / report.budget : 0;

  const estimatedPercent = report ? (report.estimated * 100) / report.budget : 0;

  const availableTimeLogs = report?.available_from_timelogs ?? 0;
  const totalCredits = report?.total_credits ?? 0;
  const totalTimelogs = report?.total_timelogs ?? 0;
  const balance = totalCredits - totalTimelogs;

  return (
    <Space className="w-full" direction="vertical" size={2}>
      <Row gutter={12} className="primary" justify="space-between">
        <Col>Available</Col>

        <Col style={{ fontWeight: 'bold' }}>
          <span className={available < 0 ? 'danger' : undefined}>{availableString}</span>
          <span> / {convertMinsToHrsMins(report?.budget)} h</span>
        </Col>
      </Row>

      <ProgressBarDouble
        mainPercent={spentPercent}
        secondaryPercent={estimatedPercent}
        mainTooltip={`Spent: ${renderTime(convertMinsToHrsMins(report?.spent ?? 0))}`}
        secondaryTooltip={`Estimated: ${renderTime(convertMinsToHrsMins(report?.estimated_original ?? 0))}`}
        backgroundTooltip={`Available: ${renderTime(availableString)}`}
      />

      <div className="primary" style={{ textAlign: 'right', display: 'none' }}>
        Internal time: {renderTime(convertMinsToHrsMins(availableTimeLogs))}
      </div>

      <Row className="primary" gutter={[8, 2]} justify="space-between">
        <Col md={infoSameLine === 'md' ? 24 : undefined} style={{ textAlign: 'right' }}>
          Refil: <span className={totalCredits < 0 ? 'danger' : undefined}>{renderTime(convertMinsToHrsMins(totalCredits))}</span>
        </Col>

        <Col md={infoSameLine === 'md' ? 24 : undefined} style={{ textAlign: 'right' }}>
          Timelog:{' '}
          <span className={totalTimelogs < 0 ? 'danger' : undefined}>{renderTime(convertMinsToHrsMins(totalTimelogs))}</span>
        </Col>

        <Col md={infoSameLine === 'md' ? 24 : undefined} style={{ textAlign: 'right' }}>
          Balance: <span className={balance < 0 ? 'danger' : undefined}>{renderTime(convertMinsToHrsMins(balance))}</span>
        </Col>
      </Row>
    </Space>
  );
};

export default TimeReportProgress;
