import { useEffect, useRef } from 'react';
import useUrlSearchParams from 'utils/useUrlSearchParams';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppTableInitialState, AppTableSlice } from './store';

interface UseUrlParamFiltersOptions {
  store: AppTableInitialState;
  storeSlice: AppTableSlice;
}

const convertValueToCorrectType = (value: string) => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  if (!Number.isNaN(Number(value))) {
    return Number(value);
  }

  return value;
};

const useUrlParamFilters = ({ store, storeSlice }: UseUrlParamFiltersOptions) => {
  const mountedRef = useRef(false);
  const dispatch = useDispatch();
  const params = useUrlSearchParams();

  const history = useHistory();

  if (!mountedRef.current) {
    mountedRef.current = true;

    if (store.urlParams) {
      dispatch(storeSlice.actions.setIgnoreFetch(true));

      if (params.has('search')) {
        dispatch(storeSlice.actions.setSearch(params.get('search') ?? ''));
      }

      const keys = Object.keys(store.params.filters);

      if (typeof store.urlParams === 'object') {
        store.urlParams.keyFilters.forEach((key) => {
          if (!keys.includes(key)) {
            keys.push(key);
          }
        });
      }

      keys.forEach((key) => {
        const valueParam = params.getAll(key);
        const valueFilter = store.params.filters[key];

        if (valueParam.length === 1 && !valueParam[0]) {
          dispatch(storeSlice.actions.setFilter({ [key]: Array.isArray(valueFilter) ? [] : undefined }));
          return;
        }

        if (valueParam.length === 1 && (!valueFilter || !Array.isArray(valueFilter))) {
          dispatch(storeSlice.actions.setFilter({ [key]: { value: convertValueToCorrectType(valueParam[0]) } }));
          return;
        }

        if (valueParam.length > 1 || (params.has(key) && Array.isArray(valueFilter))) {
          dispatch(
            storeSlice.actions.setFilter({ [key]: valueParam.map((item) => ({ value: convertValueToCorrectType(item) })) }),
          );
        }
      });

      dispatch(storeSlice.actions.setIgnoreFetch(false));
    }
  }

  useEffect(() => {
    if (store.urlParams) {
      Object.keys(store.params.filters).forEach((key) => {
        const valueFilter = store.params.filters[key];
        if (Array.isArray(valueFilter) ? valueFilter.length > 0 : !!valueFilter) {
          if (Array.isArray(valueFilter)) {
            params.delete(key);
            valueFilter.forEach((item) => {
              params.append(key, item.value.toString());
            });
          } else {
            params.set(key, valueFilter.value.toString());
          }
        } else if (params.has(key)) {
          params.delete(key);
        }
      });

      if (typeof store.urlParams === 'object') {
        store.urlParams.keyFilters.forEach((key) => {
          if (!(key in store.params.filters)) {
            params.delete(key);
          }
        });
      }
      history.replace(`?${params.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.urlParams, store.params.filters]);
};

export default useUrlParamFilters;
