import { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { FormBuilderFieldSelect } from 'types/formBuilder';

interface FieldSelectProps {
  id?: string;
  value?: SelectProps<any>['value'];
  data: FormBuilderFieldSelect;
  disabled: boolean;
  onChange?: SelectProps<any>['onChange'];
}

const FieldSelect: FC<FieldSelectProps> = ({ id, value, data, disabled, onChange }) => {
  return (
    <Select
      id={id}
      value={value}
      className={disabled ? 'read-only' : undefined}
      placeholder={data.placeholder}
      mode={data.mode === 'multiple' ? 'multiple' : undefined}
      options={data.options}
      onChange={onChange}
      allowClear
    />
  );
};

export default FieldSelect;
