import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Typography } from 'antd';
import debounce from 'lodash/debounce';
import styles from './index.module.less';

const { Paragraph } = Typography;

export interface EditableParagraphInputHandle {
  focus: () => void;
}

interface EditableParagraphInputProps {
  value?: string;
  maxLength?: number;
  onChange?: (value: string | null) => void;
  onChangeDebounce?: (value: string | null) => void;
}

const debounceCallback = debounce((callback: (value: string | null) => void, value: string | null) => {
  callback(value);
}, 600);

const EditableParagraphInput = forwardRef<EditableParagraphInputHandle, EditableParagraphInputProps>(
  function EditableParagraphInput({ value, maxLength, onChange, onChangeDebounce }, ref) {
    const [editing, setEditing] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const textarea = containerRef.current?.querySelector('textarea');
      if (textarea) {
        textarea.placeholder = 'Type in a title';
        textarea.autofocus = true;
      }
    }, [editing]);

    useImperativeHandle(ref, () => ({
      focus() {
        const textarea = containerRef.current?.querySelector('textarea');
        textarea?.focus();
      },
    }));

    return (
      <div ref={containerRef} className="w-full">
        <Paragraph
          className={styles.editableTitleTextArea}
          editable={{
            editing: !value || editing,
            onEnd: () => {
              setEditing(false);
            },
            maxLength,
            onChange: (newValue) => {
              onChange?.(newValue);

              if (onChangeDebounce) {
                debounceCallback(onChangeDebounce, newValue);
              }
            },
          }}
          onClick={() => setEditing(true)}
        >
          {value}
        </Paragraph>
      </div>
    );
  },
);

export default EditableParagraphInput;
