import { FC, useEffect, useRef } from 'react';
import { FormInstance } from 'antd';
import RichTextEditor, { RichTextEditorHandle, RichTextEditorProps } from 'components/RichTextEditor';
import { FormBuilderFieldTextEditor } from 'types/formBuilder';
import { UploadFileApi } from 'types/upload-type';

interface FieldTextEditorProps {
  id?: string;
  value?: string;
  data: FormBuilderFieldTextEditor;
  form: FormInstance;
  disabled: boolean;
  onChange?: RichTextEditorProps['onChange'];
  onTextImageUpload?: (upload: UploadFileApi) => Promise<void | string>;
}

const FieldTextEditor: FC<FieldTextEditorProps> = ({ id, value, data, form, disabled, onChange, onTextImageUpload }) => {
  const ref = useRef<RichTextEditorHandle>(null);

  useEffect(() => {
    ref.current?.updateValue(value ?? '');
  }, [value]);

  return (
    <RichTextEditor
      ref={ref}
      id={id}
      className={data.scroll ? 'h-small' : 'min-h-small'}
      scrollingContainer={data.scroll ? '.ql-editor' : undefined}
      readonly={disabled}
      placeholder={data.placeholder}
      onChange={onChange}
      onImageUploader={
        onTextImageUpload ||
        (async (upload) => {
          const currentUploads = form.getFieldValue('uploads');
          form.setFieldsValue({
            uploads: currentUploads ? [...currentUploads, upload] : [upload],
          });
        })
      }
    />
  );
};

export default FieldTextEditor;
