import { FC, useState, useMemo } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaRegCopy } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Col, Row, Tag, Typography, Popconfirm, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import UserAvatar from 'components/UserAvatar';
import { convertMinsToHrsMins, getStatusColor, parseDateToUSFormat, renderTime } from 'utils/string.utils';
import appRoutes from 'config/appRoutes';
import { UserResponse } from 'types';
import { getRequestStatusLabel } from '../../config/getRequestStatusOptions';
import { parseFormBuilder } from '../RequestUpdate';
import RequestIcon from '../RequestIcon';
import style from './index.module.less';

const { Text, Title } = Typography;

const mapTempColors = {
  hot: 'red',
  cold: 'blue',
  warm: 'orange',
};

const mapTempTooltip = {
  hot: 'Interaction in the last 7 days',
  warm: 'Interaction in the last 30 days',
  cold: 'No interaction in the last 30 days',
};

interface RequestListItemProps {
  item: any;
  to: string;
  showClient?: boolean;
  showHours?: boolean;
  onRemove?: (item: any) => Promise<void>;
}

const RequestListItem: FC<RequestListItemProps> = ({ item, to, showClient = false, showHours = false, onRemove }) => {
  const user: UserResponse = useSelector((store: any) => store.auth.user);
  const [removing, setRemoving] = useState(false);

  const { md, lg } = useBreakpoint();

  const formBuilder = useMemo(() => parseFormBuilder(item), [item]);

  const isCompleted = item.status === 'completed';

  const clientName = item.project?.customer?.business_name ?? 'No client';

  const responsible = item.related_users.find((option: any) => option.pivot?.type === 'responsible');

  const isFreelancer = user.contact.type === 'freelancer';

  let date: string;

  if (isCompleted) {
    date = item.completed_at ? parseDateToUSFormat(item.completed_at, false, true) : 'no completed date';
  } else {
    date = item.target_date ? parseDateToUSFormat(item.target_date, false, true) : 'no due date';
  }

  let hours: string;

  if (isCompleted) {
    hours = `${renderTime(convertMinsToHrsMins(item.time_spent ?? 0))}`;
  } else {
    hours =
      item.estimated_min || item.estimated_max
        ? `${convertMinsToHrsMins(item.estimated_min ?? 0)} - ${convertMinsToHrsMins(item.estimated_max ?? 0)} h`
        : 'No estimated time';
  }

  const createdAt = showHours ? moment(item.created_at) : null;

  const createdAtDiffDays = createdAt ? moment().diff(createdAt, 'days') : null;

  const temp = item.temperature as keyof typeof mapTempColors | undefined;

  return (
    <Link to={to} className={style.link} style={{ opacity: item.ghosted ? 0.5 : undefined }}>
      <Row className={style.rowItem} style={{ columnGap: 12 }} gutter={[0, 6]}>
        <Col
          flex="auto"
          className="w-full xl-w-auto"
          style={{ overflow: 'hidden', display: 'flex', alignItems: 'center', columnGap: 12 }}
        >
          <div className={style.formIcon}>
            <RequestIcon formId={formBuilder.id} />
          </div>

          <div>
            <div className={style.subTitle}>
              {item.title}
              {temp && !item.ghosted ? (
                <Tooltip title={mapTempTooltip[temp]}>
                  <Tag color={mapTempColors[temp]} className={style.temp}>
                    {temp}
                  </Tag>
                </Tooltip>
              ) : undefined}
            </div>

            <Title level={5} className={style.title} ellipsis>
              {md ? item.subject : <Tooltip title={item.subject}>{item.subject}</Tooltip>}
            </Title>
          </div>
        </Col>

        <Col flex="none" className="w-full xl-w-auto">
          <Row className={style.rowExtraInfo} align="middle" style={{ columnGap: 12 }} gutter={[0, 4]}>
            {responsible && (
              <Col className={style.colResponsible}>
                <UserAvatar user={responsible} />
              </Col>
            )}

            <Col className={style.colProject}>
              <div className={style.subTitle}>by {item.requester?.name ?? 'No user'}</div>

              {showClient ? (
                <Text ellipsis>{lg ? clientName : <Tooltip title={clientName}>{clientName}</Tooltip>}</Text>
              ) : (
                <div>
                  {isCompleted ? 'Completed' : 'Delivery'}: {date}
                </div>
              )}
            </Col>

            {showHours && (
              <Col className={style.colHours}>
                <div className={style.subTitle}>{isCompleted ? 'Spent' : 'Estimate'}</div>

                <div>{hours}</div>
              </Col>
            )}

            {createdAt && createdAtDiffDays !== null && (
              <Col className={style.colCreatedAt}>
                <div className={style.subTitle}>Created</div>

                <Tooltip title={createdAt.format('MMMM DD, YYYY HH:mm A')}>
                  <span className={createdAtDiffDays > 29 ? 'danger' : undefined}>
                    {createdAtDiffDays > 1 ? `${createdAtDiffDays} days ago` : createdAt.fromNow()}
                  </span>
                </Tooltip>
              </Col>
            )}

            <Col className={style.colStatus}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6, justifyContent: 'right' }}>
                <Tag style={{ marginRight: 0 }} color={getStatusColor(item.status)}>
                  {getRequestStatusLabel(item.status)}
                </Tag>

                {!onRemove && !isFreelancer ? (
                  <Link to={`${appRoutes.REQUESTS}/${item.uuid}/duplicate`}>
                    <FaRegCopy />
                  </Link>
                ) : undefined}
              </div>

              <Row justify="end" align="middle" wrap={false} style={{ columnGap: 12 }}>
                {showClient && (
                  <Col>
                    <Tooltip title={isCompleted ? 'Completed' : 'Delivery'}>{date}</Tooltip>
                  </Col>
                )}

                {onRemove && !isFreelancer ? (
                  <Col style={{ marginTop: 2 }}>
                    <Link to={`${appRoutes.REQUESTS}/${item.uuid}/duplicate`}>
                      <FaRegCopy />
                    </Link>
                  </Col>
                ) : undefined}

                {onRemove ? (
                  <Col style={{ textAlign: 'right', marginTop: 2 }}>
                    <Popconfirm
                      disabled={removing}
                      title="Do you want to remove this request from the task?"
                      onConfirm={(event) => {
                        event?.stopPropagation();
                        setRemoving(true);
                        onRemove(item).finally(() => setRemoving(false));
                      }}
                      onCancel={(event) => event?.stopPropagation()}
                    >
                      <CloseCircleOutlined className="red pointer" />
                    </Popconfirm>
                  </Col>
                ) : undefined}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Link>
  );
};

export default RequestListItem;
