import { FC } from 'react';
import { Col, FormInstance, Row, Space } from 'antd';
import FormDiscardButton from './buttons/FormDiscardButton';
import FormDraftButton from './buttons/FormDraftButton';
import FormSavingLoading from './buttons/FormSavingLoading';
import FormSubmitButton from './buttons/FormSubmitButton';

interface FormButtonsProps {
  form: FormInstance;
  saving?: boolean;
  onDiscard?: () => void;
  onDraft?: (values: any) => void;
  onSubmit?: (values: any) => void;
}

const FormButtons: FC<FormButtonsProps> = ({ form, saving, onDiscard, onDraft, onSubmit }) => {
  if (!onDiscard && !onDraft && !onSubmit) {
    return null;
  }

  return (
    <Row gutter={[8, 8]} justify="space-between">
      <Col>{onDiscard && <FormDiscardButton saving={saving} onDiscard={onDiscard} />}</Col>

      <Col>
        <Space>
          {!onDraft && <FormSavingLoading saving={saving} />}

          {onDraft && <FormDraftButton form={form} saving={saving} onDraft={onDraft} />}

          {onSubmit && <FormSubmitButton form={form} saving={saving} onSubmit={onSubmit} />}
        </Space>
      </Col>
    </Row>
  );
};

export default FormButtons;
