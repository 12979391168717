import { FormBuilderData, FormBuilderFormData } from 'types/formBuilder';

export function convertFormModelToFormBuilderForm(model: any): FormBuilderData {
  return {
    id: model.uuid,
    name: model.title,
    imageUrl: '',
    description: model.description ?? '',
    form: model.questions.map((item: any): FormBuilderFormData => {
      const newItem: FormBuilderFormData = {
        label: item.label,
        name: item.id,
        required: item.required,
        type: (item.type as string).toLowerCase() as any,
        answer: item.answer,
      };

      if (item.type === 'Short') {
        newItem.type = 'input';
        return newItem;
      }

      if (item.type === 'Number') {
        return {
          ...newItem,
          type: 'input',
          inputType: 'number',
        };
      }

      if (item.type === 'Paragraph') {
        newItem.type = 'textArea';
        return newItem;
      }

      if (item.type === 'Dropdown') {
        return {
          ...newItem,
          type: 'select',
          mode: 'simple',
          options: model.questions
            .filter((subItem: any) => subItem.type === 'DropdownOption' && subItem.parent === item.id)
            .map((subItem: any) => ({
              label: subItem.label,
              value: subItem.label,
            })),
        };
      }

      if (item.type === 'Checkbox') {
        return {
          ...newItem,
          type: 'checkbox',
          mode: 'simple',
          options: model.questions
            .filter((subItem: any) => subItem.parent === item.id)
            .map((subItem: any) => ({
              label: subItem.label,
              value: subItem.label,
            })),
        };
      }

      return newItem;
    }),
  };
}
