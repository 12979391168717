import { FC, useState } from 'react';
import { Button, Card, Form, message, Modal, Typography } from 'antd';
import RequestListItem from 'modules/request/components/RequestListItem';
import SelectSearch from 'components/SelectSearch';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { localRandomUuid } from 'utils/string.utils';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';
import appRoutes from 'config/appRoutes';
import style from './index.module.less';

const { Text } = Typography;

interface RequestCardProps {
  taskId: string;
  projectUuid: string;
  requests: any[];
  onRelate: (requests: Record<string, any>[]) => void;
  onRemove: (request: any) => void;
}

const RequestCard: FC<RequestCardProps> = ({ taskId, projectUuid, requests, onRelate, onRemove }) => {
  const [openRelateModal, setOpenRelateModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const [form] = Form.useForm();

  const onRelateRequestHandler = async () => {
    setSaving(true);

    try {
      const values = await form.validateFields();

      const response = await apiRequests.put(`${apiRoutes.PROJECT_TASKS}/${taskId}`, {
        ref_token: localRandomUuid,
        add_requests: [values.request_id],
      });

      setOpenRelateModal(false);

      message.success('Request related to the task');

      onRelate(response.data.data.requests);
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const onRemoveHandler = async (item: any) => {
    await apiRequests.put(`${apiRoutes.PROJECT_TASKS}/${taskId}`, { remove_requests: [item.uuid] });

    onRemove(item);
  };

  return (
    <Card
      className={style.card}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span className="primary">Request</span>

          {requests.length === 0 && (
            <Button type="primary" onClick={() => setOpenRelateModal(true)}>
              Relate
            </Button>
          )}
        </div>
      }
      bodyStyle={{ padding: requests.length ? 8 : 0 }}
    >
      <Modal
        title={<Text className="primary">Relate this task to a request</Text>}
        visible={openRelateModal}
        confirmLoading={saving}
        okText="Relate"
        onOk={onRelateRequestHandler}
        onCancel={() => {
          setOpenRelateModal(false);
        }}
        afterClose={() => {
          form.resetFields();
        }}
        destroyOnClose
      >
        <Form form={form} layout="vertical">
          <SelectSearch
            name="request_id"
            label="Request"
            labelKey="subject"
            formItemClassName="mb-0"
            api={apiRoutes.REQUESTS}
            rules={[
              {
                required: true,
                message: 'This Field is required.',
              },
            ]}
            defaultFilter={
              projectUuid
                ? {
                    filterBy: 'project.uuid',
                    value: projectUuid,
                  }
                : undefined
            }
            searchKey="search_term"
            defaultParams={{
              'filters[projects_requests.status]': ['new', 'waiting_approval', 'approved', 'needs_attention', 'in_progress'],
            }}
            render={(request: any) => (
              <span>
                {request.title} - {request.subject}
              </span>
            )}
          />
        </Form>
      </Modal>

      <div>
        {requests.map((item: any) => (
          <RequestListItem key={item.uuid} to={`${appRoutes.REQUESTS}/${item.uuid}`} item={item} onRemove={onRemoveHandler} />
        ))}
      </div>
    </Card>
  );
};

export default RequestCard;
