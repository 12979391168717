import { FC } from 'react';
import { Tooltip } from 'antd';
import styles from './index.module.less';

interface ProgressBarDoubleProps {
  mainPercent: number;
  secondaryPercent: number;
  mainTooltip?: string;
  secondaryTooltip?: string;
  backgroundTooltip?: string;
}

const ProgressBarDouble: FC<ProgressBarDoubleProps> = ({
  mainPercent,
  secondaryPercent,
  mainTooltip,
  secondaryTooltip,
  backgroundTooltip,
}) => {
  const biggerPercent = mainPercent > secondaryPercent ? mainPercent : secondaryPercent;

  let backgroundPercent = 100 - biggerPercent;

  backgroundPercent = backgroundPercent < 0 ? 0 : backgroundPercent;

  backgroundPercent = backgroundPercent > 100 ? 100 : backgroundPercent;

  return (
    <div className={`${styles.progress}${mainPercent > 100 ? ` ${styles.danger}` : ''}`}>
      <Tooltip title={backgroundTooltip} placement="topLeft">
        <div
          className={styles.backgroundBar}
          style={{
            width: `calc(${backgroundPercent}% + 20px)`,
            left: biggerPercent < 3 ? undefined : `calc(${biggerPercent}% - 20px)`,
          }}
        />
      </Tooltip>

      <Tooltip title={secondaryTooltip}>
        <div
          className={`${styles.secondaryBar}${secondaryPercent > 100 ? ` ${styles.danger}` : ''}`}
          style={{
            width: mainPercent >= secondaryPercent ? `${secondaryPercent}%` : `calc(${secondaryPercent - mainPercent}% + 20px)`,
            left: mainPercent >= secondaryPercent || mainPercent < 3 ? undefined : `calc(${mainPercent}% - 20px)`,
          }}
        />
      </Tooltip>

      <Tooltip title={mainTooltip}>
        <div
          className={`${styles.mainBar}${mainPercent > 100 ? ` ${styles.danger}` : ''}`}
          style={{ width: `${mainPercent}%` }}
        />
      </Tooltip>
    </div>
  );
};

export default ProgressBarDouble;
