import { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { SlPencil } from 'react-icons/sl';
import ProjectSelect from 'modules/project/components/ProjectSelect';
import appRoutes from 'config/appRoutes';
import { RefSelectProps } from 'antd/es/select';

interface ProjectTagProps {
  project: any;
  canEdit?: boolean;
  onChange: (project: any) => void;
}

const ProjectTag: FC<ProjectTagProps> = ({ project, canEdit = true, onChange }) => {
  const [edit, setEdit] = useState(!project);
  const [openSelect, setOpenSelect] = useState(false);

  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    if (!project) {
      const timeout = setTimeout(() => {
        setOpenSelect(true);
      }, 300);

      setTimeout(() => selectRef.current?.focus(), 0);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: 'inline-flex', width: '100%', gap: 6, alignItems: 'center' }}>
      {edit || !project ? (
        <>
          <ProjectSelect
            ref={selectRef}
            open={!project ? openSelect : undefined}
            className="mb-0"
            bordered={false}
            onlyActive
            onDropdownVisibleChange={setOpenSelect}
            onChange={(_, option: any) => {
              setEdit(false);
              if (option?.extra?.uuid !== project?.uuid) {
                onChange(option?.extra);
              }
            }}
          />

          <div
            className="pointer"
            style={{ fontSize: '20px', paddingRight: 10, lineHeight: 1 }}
            onClick={() => {
              setEdit(false);
            }}
          >
            <CloseCircleOutlined className="red" />
          </div>
        </>
      ) : (
        <>
          <Link
            to={`${appRoutes.PROJECTS}/${project.uuid}`}
            className="secondary"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {project.customer?.business_name} - {project.title}
          </Link>

          {canEdit ? (
            <Button
              size="small"
              type="text"
              icon={<SlPencil className="secondary" style={{ verticalAlign: 'middle' }} />}
              onClick={() => setEdit(true)}
            />
          ) : undefined}
        </>
      )}
    </div>
  );
};

export default ProjectTag;
