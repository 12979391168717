// BASE APPS
const CLIENT_PORTAL = '';

const appRoutes = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET: '/reset',

  // BASE APPS
  CLIENT_PORTAL,

  // CLIENT PORTAL ROUTES
  DASHBOARD: `${CLIENT_PORTAL}/`,
  ROLES: `${CLIENT_PORTAL}/roles`,
  USERS: `${CLIENT_PORTAL}/users`,
  LOGS: `${CLIENT_PORTAL}/logs`,
  GROUPS: `${CLIENT_PORTAL}/groups`,

  ONBOARDING: `${CLIENT_PORTAL}/onboarding`,
  ONBOARDING_SUBMISSIONS: `${CLIENT_PORTAL}/onboarding/submissions`,
  ONBOARDING__requirements: `${CLIENT_PORTAL}/onboarding/requirements`,
  ONBOARDING__authentication: `${CLIENT_PORTAL}/onboarding/process`,
  ONBOARDING__orders: `${CLIENT_PORTAL}/onboarding/orders`,
  ONBOARDING__welcome: `${CLIENT_PORTAL}/onboarding/welcome`,
  ONBOARDING__information: `${CLIENT_PORTAL}/onboarding/information`,

  CLIENTS: `${CLIENT_PORTAL}/clients`,
  ACCOUNT: `${CLIENT_PORTAL}/account`,
  CUSTOMERS: `${CLIENT_PORTAL}/customers`,
  CONTACTS: `${CLIENT_PORTAL}/people`,
  CONTENT: `${CLIENT_PORTAL}/content`,
  FORMS: `${CLIENT_PORTAL}/forms`,
  SUBMIT_FORM: `${CLIENT_PORTAL}/submit`,
  SUBMISSIONS: `${CLIENT_PORTAL}/forms/submissions`,
  REQUESTS: `${CLIENT_PORTAL}/requests`,
  PRODUCT_TYPES: `${CLIENT_PORTAL}/product-types`,
  PROJECTS: `${CLIENT_PORTAL}/projects`,
  ADVERTISING: `${CLIENT_PORTAL}/advertising`,
  ANALYTICS: `${CLIENT_PORTAL}/analytics`,
  DISCOUNTS: `${CLIENT_PORTAL}/discounts`,
  INBOX: `${CLIENT_PORTAL}/inbox`,
  TERMS: `${CLIENT_PORTAL}/terms`,
  PRIVACY: `${CLIENT_PORTAL}/privacy`,
  TASKS: `${CLIENT_PORTAL}/tasks`,
  PERSONAL_TASKS: `${CLIENT_PORTAL}/personal-tasks`,
  SERVICES: `${CLIENT_PORTAL}/services`,

  INTEGRATIONS: `${CLIENT_PORTAL}/integrations`,
  INTEGRATIONS_GOOGLE: `${CLIENT_PORTAL}/integrations/google`,

  STATUS_REPORT: `${CLIENT_PORTAL}/status-report`,
  TIME_LOG_REPORT: `${CLIENT_PORTAL}/timelog-report`,
  CMS: `${CLIENT_PORTAL}/cms`,
  ADMINISTRATION: `${CLIENT_PORTAL}/administration`,
  REPORT: `${CLIENT_PORTAL}/report`,
  REPORT_USER_PERFORMANCE: `${CLIENT_PORTAL}/report/user-performance`,

  PROJECT_TiME_REPORT: `${CLIENT_PORTAL}/project-time-report`,
  ACTIVITY_LOG: `${CLIENT_PORTAL}/activity-log`,

  GOOGLE_OAUTH: `${CLIENT_PORTAL}/google/oauth`,
  CALENDAR: `${CLIENT_PORTAL}/calendar`,

  COMMENT_TEMPLATES: `${CLIENT_PORTAL}/comment-templates`,

  FILE_VIEWER: `${CLIENT_PORTAL}/file-viewer`,
};

export default appRoutes;
