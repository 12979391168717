import { clientsReducer } from 'modules/client/store/clients';
import contacts from 'modules/contact/store/contacts-slice';
import commentTemplates from 'modules/comment-templates/store/comment-templates-slice';
import customers from 'modules/customer/store/customers-slice';
import customerSelect from 'modules/customer/store/customerSelect-slice';
import inbox from 'modules/inbox/store/inbox-slice';
import emailLogs from 'modules/log/store/email-logs-slice';
import productTypes from 'modules/product/store/productTypes-slice';
import { projectsReducer } from 'modules/project/store/project';
import { projectRequestsReducer } from 'modules/project/store/projectRequests';
import { projectsAdvertisingReducer } from 'modules/advertising/store/projectAdvertising';
import { projectsAnalyticsReducer } from 'modules/analytics/store/projectAnalytics';
import { projectsTimeReportReducer } from 'modules/projectTimeReport/store/projectTimeReport';
import { projectTasksReducer } from 'modules/project/store/projectTasks';
import { projectTasksTimelogReducer } from 'modules/project/store/projectTasksTimelog';
import { projectCreditsReducer } from 'modules/project/store/projectCredits';
import { projectCreditHistoryReducer } from 'modules/project/store/projectCreditHistory';
import projectSelect from 'modules/project/store/projectSelect-slice';
import reportUserPerformance from 'modules/report/store/reportUserPerformance-slice';
import projectTypeSelect from 'modules/project/store/projectTypeSelect-slice';
import analytics from 'modules/analytics/store/analytics-slice';
import { requestsReducer } from 'modules/request/store/request';
import { requestTimelogReducer } from 'modules/request/store/requestTimelog';
import { tasksReducer } from 'modules/tasks/store/task';
import { taskTimelogReportReducer } from 'modules/tasks/store/taskTimelogReport';
import { tasksPersonalReducer } from 'modules/personal-tasks/store/tasksPersonal';
import permissions from 'modules/role/store/permissions-slice';
import forms from 'modules/forms/store/reducer';
import bluePrints from 'modules/forms/store/blueprints/bluePrints-slice';
import answers from 'modules/forms/store/answers/reducer';
import roles from 'modules/role/store/roles-slice';
import groups from 'modules/groups/store/groups-slice';
import content from 'modules/content/store/content-slice';
import { timelogReportReducer } from 'modules/timelogReport/store/timelogReport';
import { projectTimelogReportReducer } from 'modules/project/store/projectTimelogReport';
import administration from 'modules/administration/store/administration-slice';
import dashboardProjects from 'modules/dashboard/store/dashboardProjects-slice';
import dashboardFailedPayments from 'modules/dashboard/store/dashboardFailedPayments-slice';
import dashboardUserPerformance from 'modules/dashboard/store/dashboardUserPerformance-slice';
import { dashboardTasksReducer } from 'modules/dashboard/store/dashboardTasks';

import activeLogs from './container/activeLogs/activeLogs-slice';
import weeklyLogs from './container/weeklyLogs/weeklyLogs-slice';
import auth from './container/auth/auth-slice';
import versionUpdate from './container/versionUpdate/versionUpdate-slice';
import globalLog from './container/globalLog/globalLog-slice';
import globalTaskModal from './container/globalTaskModal/globalTaskModal-slice';

export default {
  contacts,
  commentTemplates,
  customers,
  customerSelect,
  roles,
  groups,
  inbox,
  auth,
  emailLogs,
  permissions,
  analytics,
  projectSelect,
  projectTypeSelect,
  tableRequests: requestsReducer,
  requestTimelog: requestTimelogReducer,
  tableTasks: tasksReducer,
  taskTimelogReport: taskTimelogReportReducer,
  tableTasksPersonal: tasksPersonalReducer,
  tableProjectTasks: projectTasksReducer,
  tableProjectRequests: projectRequestsReducer,
  tableProjects: projectsReducer,
  projectsAdvertising: projectsAdvertisingReducer,
  projectsAnalytics: projectsAnalyticsReducer,
  projectsTimeReport: projectsTimeReportReducer,
  tableProjectTasksTimelog: projectTasksTimelogReducer,
  tableProjectCredits: projectCreditsReducer,
  tableProjectCreditHistory: projectCreditHistoryReducer,
  clients: clientsReducer,
  activeLogs,
  weeklyLogs,
  timelogReport: timelogReportReducer,
  projectTimelogReport: projectTimelogReportReducer,
  reportUserPerformance,
  globalLog,
  globalTaskModal,
  content,
  forms,
  answers,
  productTypes,
  bluePrints,
  administration,
  dashboardFailedPayments,
  dashboardProjects,
  dashboardUserPerformance,
  dashboardTasks: dashboardTasksReducer,
  versionUpdate,
};
