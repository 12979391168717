import { put, takeLatest } from 'redux-saga/effects';
import { createAppTableSaga, createAppTableStore } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';
import { convertMinsToHrsMins } from 'utils/string.utils';

export const projectTimelogReportSlice = createAppTableStore({
  name: 'projectTimelogReport' as const,
  url: apiRoutes.TIME_LOG_REPORT,
  initialState: {
    params: {
      filters: {
        user_uuid: [],
        user_type: [{ value: 'regular_user' }, { value: 'freelancer' }],
        source: [{ value: 'task' }, { value: 'request' }],
        category: [{ value: 'billable' }, { value: 'non-billable' }, { value: 'sgm-non-billable' }],
      },
    },
    fixedParams: { 'filters[status][]': 'completed' },
    paramFilterFields: {
      date_start: 'date_from',
      date_end: 'date_to',
    },
    totalTime: null as null | string,
  },
  reducers: {
    setTotalTime: (state, { payload }: { payload: string; type: string }) => {
      return {
        ...state,
        totalTime: payload,
      };
    },
  },
});

function* handleApiResponseSagaListener({ payload }: { payload: any }) {
  yield put(
    projectTimelogReportSlice.actions.setTotalTime(
      convertMinsToHrsMins(Number(payload.tasks_total_time ?? 0) + Number(payload.requests_total_time ?? 0)),
    ),
  );
}

export const projectTimelogReportSaga = createAppTableSaga(projectTimelogReportSlice, function* customGenerator() {
  yield takeLatest(projectTimelogReportSlice.actions.handleApiResponse, handleApiResponseSagaListener);
});

export const projectTimelogReportReducer = projectTimelogReportSlice.reducer;
