import { AxiosResponse } from 'axios';
import moment from 'moment';
import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';
import apiRoutes from 'config/apiRoute';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { RootState } from 'store';
import { UserResponse } from 'types';
import {
  getDashboardUserPerformance,
  setDashboardUserPerformanceData,
  setDashboardUserPerformanceError,
  setDashboardUserPerformanceLoading,
} from './dashboardUserPerformance-slice';

const { get } = api;

function* getDashboardUserPerformanceSagaListener() {
  const user: UserResponse = yield select((store: RootState) => store.auth.user);

  try {
    yield put(setDashboardUserPerformanceLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.USER_PERFORMANCE_REPORT, {
      date_from: moment().subtract(30, 'day').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      'filters[user_uuid][]': user.uuid,
    });
    yield put(setDashboardUserPerformanceData(res.data.data));
  } catch (error) {
    yield put(setDashboardUserPerformanceError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardFailedPaymentsSaga(): Generator<StrictEffect> {
  yield takeEvery(getDashboardUserPerformance.type, getDashboardUserPerformanceSagaListener);
}

export default dashboardFailedPaymentsSaga;
