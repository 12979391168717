import { createAppTableSaga, createAppTableStore } from 'components/AppTable/store';

export const projectCreditHistorySlice = createAppTableStore({
  name: 'tableProjectCreditHistory' as const,
  url: '',
  initialState: {
    params: {
      sort: { column: 'date', order: 'descend' },
    },
    pagination: null,
  },
  transformData: (data) => {
    return data.map((item) => ({
      ...item,
      // @ts-ignore
      uuid: item.uuid ?? window.crypto.randomUUID(),
    }));
  },
});

export const projectCreditHistorySaga = createAppTableSaga(projectCreditHistorySlice);

export const projectCreditHistoryReducer = projectCreditHistorySlice.reducer;
