import moment from 'moment';
import { put, takeLatest } from 'redux-saga/effects';
import { createAppTableSaga, createAppTableStore, createPersist } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';
import { convertMinsToHrsMins } from 'utils/string.utils';

const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');

export const timelogReportSlice = createAppTableStore({
  name: 'timelogReport' as const,
  url: apiRoutes.TIME_LOG_REPORT,
  initialState: {
    params: {
      filters: {
        date_start: { value: startOfWeek },
        date_end: { value: endOfWeek },
        project_uuid: [],
        user_uuid: [],
        project_type: [{ value: 'retainer' }, { value: 'hosting' }, { value: 'others' }],
        user_type: [{ value: 'regular_user' }, { value: 'freelancer' }],
        source: [{ value: 'task' }, { value: 'request' }],
        category: [{ value: 'billable' }, { value: 'non-billable' }, { value: 'sgm-non-billable' }],
      },
    },
    paramFilterFields: {
      date_start: 'date_from',
      date_end: 'date_to',
    },
    urlParams: { keyFilters: ['is_retainer', 'date_start', 'date_end'] },
    totalTime: null as null | string,
  },
  reducers: {
    setTotalTime: (state, { payload }: { payload: string; type: string }) => {
      return {
        ...state,
        totalTime: payload,
      };
    },
  },
});

function* handleApiResponseSagaListener({ payload }: { payload: any }) {
  yield put(
    timelogReportSlice.actions.setTotalTime(
      convertMinsToHrsMins(Number(payload.tasks_total_time ?? 0) + Number(payload.requests_total_time ?? 0)),
    ),
  );
}

export const timelogReportSaga = createAppTableSaga(timelogReportSlice, function* customGenerator() {
  yield takeLatest(timelogReportSlice.actions.handleApiResponse, handleApiResponseSagaListener);
});

export const timelogReportReducer = createPersist(timelogReportSlice, []);
