import Quill from 'quill';
import type Parchment from 'parchment';

const LinkBlot: typeof Parchment.Inline = Quill.import('formats/link');

class ClickableLink extends LinkBlot {
  static create(value: string) {
    const parsedValue = value.startsWith('www.') ? `https://${value}` : value;

    const node = super.create(parsedValue) as HTMLElement;
    node.setAttribute('contenteditable', 'false');

    return node;
  }
}

export default ClickableLink;
