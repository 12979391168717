import { FC, ReactNode } from 'react';
import { Form, InputProps } from 'antd';
import TimeRangeCustom from 'components/TimeRangeCustom';
import { convertHrsMinsToMins } from 'utils/string.utils';

interface FieldTimeRangeCustomProps {
  label: ReactNode;
  name: string;
  className?: string;
  disabled?: boolean;
  onBlur?: InputProps['onBlur'];
}

const FieldTimeRangeCustom: FC<FieldTimeRangeCustomProps> = ({ label, name, className, disabled, onBlur }) => {
  return (
    <Form.Item
      label={label}
      name={name}
      className={className}
      rules={[
        {
          validator(_, value) {
            if (!value) {
              return Promise.resolve();
            }

            const [hourMin, hourMax] = value.split('-');
            const min = hourMin ? convertHrsMinsToMins(hourMin) : 0;
            const max = hourMax ? convertHrsMinsToMins(hourMax) : 0;
            return min <= max ? Promise.resolve() : Promise.reject(new Error('Max time should be bigger than min time'));
          },
        },
      ]}
    >
      <TimeRangeCustom onBlur={onBlur} disabled={disabled} />
    </Form.Item>
  );
};

export default FieldTimeRangeCustom;
