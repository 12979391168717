import { FC } from 'react';
import { useHistory } from 'react-router';
import { Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { convertMinsToHrsMins, renderTime } from 'utils/string.utils';
import appRoutes from 'config/appRoutes';

import useWeeklyTimer from './useWeeklyTimer';

interface WeeklyTimerProps {}

const WeeklyTimer: FC<WeeklyTimerProps> = () => {
  const { weeklyLogs } = useWeeklyTimer();
  const history = useHistory();

  if (!weeklyLogs.data || !weeklyLogs.today) {
    return null;
  }

  return (
    <div
      onClick={() => {
        history.push(`${appRoutes.TIME_LOG_REPORT}?current_week=true`);
      }}
      style={{ cursor: 'pointer' }}
    >
      <Space>
        <div className="mb-0" style={{ lineHeight: '20px', fontSize: 14 }}>
          <p style={{ marginBottom: 0, whiteSpace: 'nowrap' }}>T: {renderTime(convertMinsToHrsMins(weeklyLogs.todayTotal))}</p>
          <p style={{ color: weeklyLogs.isBelow ? '#FF6672' : undefined, marginBottom: 0, whiteSpace: 'nowrap' }}>
            W:{' '}
            {renderTime(
              convertMinsToHrsMins(
                Number(weeklyLogs.data?.requests_total_time ?? 0) + Number(weeklyLogs.data?.tasks_total_time ?? 0),
              ),
            )}
          </p>
        </div>

        {weeklyLogs.loading && <LoadingOutlined spin />}
      </Space>
    </div>
  );
};

export default WeeklyTimer;
