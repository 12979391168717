import { FC, createContext, Context } from 'react';
import { Form, FormInstance } from 'antd';

export const EditableContext = createContext<FormInstance<any> | null>(null) as Context<FormInstance<any>>;

interface EditableRowProps {
  index: number;
}

const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form component={false} form={form}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableRow;
