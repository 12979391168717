import { useCallback, useRef } from 'react';
import * as Y from 'yjs';
import Quill from 'quill';
import { generateHexColor, getAvatar } from 'utils/string.utils';
import type { WebrtcConn, WebrtcProvider } from 'y-webrtc';
import type { AwarenessUser, CollaborativeEditorOptions } from 'types/richTextEditor';
import type { UserResponse } from 'types';
import { QuillBinding } from 'y-quill';
import CustomCursors from './CustomCursors';

const updateCursor = (quillCursors: any, aw: any, clientId: any, doc: any, type: any) => {
  try {
    if (aw && aw.cursor && clientId !== doc.clientID) {
      const user = aw.user || {};
      const color = user.color || '#ffa500';
      const name = user.name || `User: ${clientId}`;
      quillCursors.createCursor(clientId.toString(), name, color);
      const anchor = Y.createAbsolutePositionFromRelativePosition(Y.createRelativePositionFromJSON(aw.cursor.anchor), doc);
      const head = Y.createAbsolutePositionFromRelativePosition(Y.createRelativePositionFromJSON(aw.cursor.head), doc);
      if (anchor && head && anchor.type === type) {
        quillCursors.moveCursor(clientId.toString(), { index: anchor.index, length: head.index - anchor.index });
      }
    } else {
      quillCursors.removeCursor(clientId.toString());
    }
  } catch (err) {
    console.error(err);
  }
};

interface CustomCursorsParams {
  yProvider?: WebrtcProvider;
  collaborativeEditor?: CollaborativeEditorOptions;
  user: UserResponse | null;
}

type AwarenessChangeHandler = (event: any, source: any, cursors: CustomCursors) => void;

export function useAwareness({ yProvider, collaborativeEditor, user }: CustomCursorsParams) {
  const cursorTimeoutMapRef = useRef(new Map<string, NodeJS.Timeout>());
  const awarenessChangeHandlerRef = useRef<AwarenessChangeHandler>(() => {});
  const lastMessagePeerId = useRef<string | null>(null);

  const toggleCursorFlag = (id: number, cursors: CustomCursors) => {
    const idString = id.toString();

    if (cursorTimeoutMapRef.current.has(idString)) {
      clearTimeout(cursorTimeoutMapRef.current.get(idString));
    }

    cursors.toggleFlag(idString, true);

    cursorTimeoutMapRef.current.set(
      idString,
      setTimeout(() => {
        cursors.toggleFlag(idString, false);
        cursorTimeoutMapRef.current.delete(idString);
      }, 3000),
    );
  };

  awarenessChangeHandlerRef.current = (event, source, cursors) => {
    if (source === 'local' || !yProvider) return;

    event.added.forEach((x: number) => toggleCursorFlag(x, cursors));

    event.updated.forEach((x: number) => toggleCursorFlag(x, cursors));

    const users = new Map<string, AwarenessUser>();
    const currentUsers = collaborativeEditor?.users;

    yProvider.awareness.getStates().forEach((state) => {
      if (state?.user && state.user.uuid !== user?.uuid && !users.has(state.user.uuid)) {
        users.set(state.user.uuid, state.user);
      }
    });

    if (
      !currentUsers ||
      Array.from(users.keys()).find((x) => !currentUsers.has(x)) ||
      Array.from(currentUsers.keys()).find((x) => !users.has(x))
    ) {
      collaborativeEditor?.onUserUpdated?.(users);
    }
  };

  const bindAwareness = useCallback(
    (yText: Y.Text, editor: Quill) => {
      if (!yProvider || !user) {
        return () => {};
      }

      const quillCursors: CustomCursors = editor.getModule('customCursors');

      if (!quillCursors) {
        return () => {};
      }

      const cursorHandler = () => {
        const sel = editor.getSelection();
        const aw = yProvider.awareness.getLocalState();

        if (sel === null) {
          if (aw !== null) {
            yProvider.awareness.setLocalStateField('cursor', null);
          }
        } else {
          const anchor = Y.createRelativePositionFromTypeIndex(yText, sel.index);
          const head = Y.createRelativePositionFromTypeIndex(yText, sel.index + sel.length);

          if (
            !aw ||
            !aw.cursor ||
            !Y.compareRelativePositions(anchor, aw.cursor.anchor) ||
            !Y.compareRelativePositions(head, aw.cursor.head)
          ) {
            yProvider.awareness.setLocalStateField('cursor', {
              anchor,
              head,
            });
          }
        }

        // update all remote cursor locations
        yProvider.awareness.getStates().forEach((aww, clientId) => {
          updateCursor(quillCursors, aww, clientId, yProvider.doc, yText);
        });
      };

      const awarenessChangeHandler = (event: any, source: WebrtcConn | string) => {
        const states = yProvider.awareness.getStates();

        event.added.forEach((id: number) => {
          updateCursor(quillCursors, states.get(id), id, yProvider.doc, yText);
        });

        event.updated.forEach((id: number) => {
          updateCursor(quillCursors, states.get(id), id, yProvider.doc, yText);
        });

        event.removed.forEach((id: number) => {
          quillCursors.removeCursor(id.toString());
        });

        awarenessChangeHandlerRef.current(event, source, quillCursors);
      };

      const providerPeersHandler = (event: any) => {
        event.added.forEach((peerId: string) => {
          const webRtcCon = yProvider.room?.webrtcConns?.get(peerId);

          if (!webRtcCon) return;

          webRtcCon.peer.on('data', () => {
            lastMessagePeerId.current = peerId;
          });
        });
      };

      const docUpdateCursor = (_update: any, source: any) => {
        if (source instanceof QuillBinding || !lastMessagePeerId.current) {
          return;
        }

        yProvider.awareness.getStates().forEach((state, clientId) => {
          if (state.peerId === lastMessagePeerId.current) {
            toggleCursorFlag(clientId, quillCursors);
          }
        });
      };

      yProvider.on('peers', providerPeersHandler);

      yProvider.doc.on('update', docUpdateCursor);

      yProvider.awareness.on('change', awarenessChangeHandler);

      editor.on('text-change', cursorHandler);

      editor.on('selection-change', cursorHandler);

      yProvider.awareness.setLocalStateField('user', {
        uuid: user.uuid,
        name: user.name,
        color: generateHexColor(getAvatar(user)?.value),
      });

      setTimeout(() => {
        if (yProvider.room) {
          yProvider.awareness.setLocalStateField('peerId', yProvider.room.peerId);
        }
      });

      return () => {
        quillCursors.clearCursors();
        editor.off('text-change', cursorHandler);
        editor.off('selection-change', cursorHandler);
        yProvider.doc.off('update', docUpdateCursor);
        yProvider.awareness.off('change', awarenessChangeHandler);
        yProvider.off('peers', providerPeersHandler);
      };
    },
    [user, yProvider],
  );

  return {
    bindAwareness,
  };
}
