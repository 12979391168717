import { FC, useCallback, useEffect, useState } from 'react';
import { Card, message, Result, Skeleton } from 'antd';
import { convertFormModelToFormBuilderForm } from 'modules/forms/utils';
import { convertMinsToHrsMins, localRandomUuid } from 'utils/string.utils';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import useWebSocket from 'utils/useWebSocket';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';
import { FormBuilderData } from 'types/formBuilder';
import cloneDeep from 'lodash/cloneDeep';
import RequestUpdateDraft from './RequestUpdateDraft';
import RequestUpdateNoDraft from './RequestUpdateNoDraft';
import { RequestFormProps } from '../RequestForm';

export const parseFormBuilder = (data: any): FormBuilderData => {
  const { description } = data;
  let questions: Record<string, any>[] = [];

  try {
    const desJson = typeof description === 'string' ? JSON.parse(description) : description;

    if (typeof desJson === 'object') {
      if (!Array.isArray(desJson)) {
        return desJson;
      }

      questions = desJson;
    }
  } catch (error) {
    questions = [
      {
        id: 'text',
        key: null,
        type: 'TextEditor',
        label: 'Description',
        answer: description,
        formType: 'Input',
        required: true,
        isQuestion: true,
      },
    ];
  }

  return convertFormModelToFormBuilderForm({
    uuid: data.uuid,
    title: data.title,
    description: data.description,
    questions,
  });
};

const parseData = (data: Record<string, any>) => {
  data.estimated_time_range = `${convertMinsToHrsMins(data.estimated_min)} - ${convertMinsToHrsMins(data.estimated_max)}`;

  return data;
};

export const fetchRequest = async (uuid: string, params?: Record<string, any>) => {
  const response = await apiRequests.get(`${apiRoutes.REQUESTS}/${uuid}`, params);

  return parseData(response.data.data);
};

interface RequestUpdateProps {
  uuid: string;
  projectUuid?: string;
  pageUrl: string;
  wrapSize: 'xl' | 'xxl';
  highlightCommentUuid?: string;
  goBack?: boolean;
  noPadding?: boolean;
  showTimeReport?: boolean;
}

const RequestUpdate: FC<RequestUpdateProps> = ({
  uuid,
  projectUuid,
  pageUrl,
  wrapSize,
  highlightCommentUuid,
  goBack = false,
  noPadding = false,
  showTimeReport,
}) => {
  const [fetching, setFetching] = useState(true);
  const [request, setRequest] = useState<any>();
  const [formBuilder, setFormBuilder] = useState<FormBuilderData>();
  const [resetForm, setResetForm] = useState<RequestFormProps['resetForm']>();

  const isDraft = request?.status === 'draft';

  const saveRequest = async (values: any) => {
    values.ref_token = localRandomUuid;

    const response = await apiRequests.put(`${apiRoutes.REQUESTS}/${uuid}?with=project_time,uploads`, values);

    const data = parseData(response.data.data);

    if (values.status === 'new' && request?.status === 'draft') {
      message.success('Request submitted');
    }

    setFormBuilder(parseFormBuilder(data));

    setRequest((oldData: any) => ({
      ...data,
      tasks: oldData.tasks,
    }));

    return response;
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await fetchRequest(uuid, {
        with: 'total_timelogs,project_time,uploads,tasks',
      });

      setFormBuilder(parseFormBuilder(data));

      setRequest(data);
    } catch (error) {
      asyncErrorHandler(error);
    }
  }, [uuid]);

  useEffect(() => {
    setFetching(true);

    fetchData().finally(() => setFetching(false));
  }, [fetchData]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.request',
      callback: (event: any) => {
        if (
          event.action === 'update' &&
          event.id === request?.uuid &&
          event.payload.ref_token !== localRandomUuid &&
          formBuilder
        ) {
          const oldRequest = cloneDeep(request);
          const oldFormBuilder = cloneDeep(formBuilder);

          fetchData()
            .then(() => {
              setResetForm({ oldRequest, oldFormBuilder });
            })
            .catch(() => {});
        }
      },
    },
  });

  if (!fetching && !request) {
    return (
      <Card
        bordered={false}
        bodyStyle={{ padding: noPadding ? '0 0 8px' : undefined, paddingBottom: !noPadding ? 8 : undefined }}
      >
        <Result status="404" subTitle="Sorry, the page you visited does not exist." title="404" />
      </Card>
    );
  }

  if (fetching) {
    return (
      <Card
        bordered={false}
        bodyStyle={{ padding: noPadding ? '0 0 8px' : undefined, paddingBottom: !noPadding ? 8 : undefined }}
      >
        <Skeleton />
      </Card>
    );
  }

  if (isDraft) {
    return (
      <RequestUpdateDraft
        request={request}
        formBuilder={formBuilder}
        projectUuid={projectUuid}
        pageUrl={pageUrl}
        resetForm={resetForm}
        noPadding={noPadding}
        goBack={goBack}
        onSaveRequest={saveRequest}
      />
    );
  }

  return (
    <RequestUpdateNoDraft
      request={request}
      formBuilder={formBuilder}
      projectUuid={projectUuid}
      pageUrl={pageUrl}
      wrapSize={wrapSize}
      noPadding={noPadding}
      highlightCommentUuid={highlightCommentUuid}
      resetForm={resetForm}
      goBack={goBack}
      showTimeReport={showTimeReport}
      setRequest={setRequest}
      onFetchRequest={fetchData}
      onSaveRequest={saveRequest}
    />
  );
};

export default RequestUpdate;
