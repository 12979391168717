import { FC } from 'react';
import { Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface FormSavingLoadingProps {
  saving?: boolean;
}

const FormSavingLoading: FC<FormSavingLoadingProps> = ({ saving }) => {
  return (
    <Typography.Text type="secondary">
      {saving ? (
        <>
          <span className="mr-2">Saving</span>
          <Spin size="small" indicator={<LoadingOutlined spin />} />
        </>
      ) : (
        'Saved'
      )}
    </Typography.Text>
  );
};

export default FormSavingLoading;
