import { Alert, Button, Space, Typography } from 'antd';
import { FC } from 'react';

const { Text } = Typography;

interface AlertSelectedRowsProps {
  dataLength: number;
  rowsTotal: number;
  selectedRowKeys: (string | number)[];
  selectedAllPages: boolean;
  onToggleSelectedAllPages: () => void;
}

const AlertSelectedRows: FC<AlertSelectedRowsProps> = ({
  rowsTotal,
  dataLength,
  selectedRowKeys,
  selectedAllPages,
  onToggleSelectedAllPages,
}) => {
  if (!selectedRowKeys.length) {
    return null;
  }

  return (
    <Alert
      style={{ marginBottom: 12 }}
      message={
        <Space>
          <Text>
            You have selected <Text strong>{selectedAllPages ? rowsTotal : selectedRowKeys.length}</Text> records
          </Text>

          {selectedRowKeys?.length >= dataLength ? (
            <Button className="primary" onClick={onToggleSelectedAllPages} size="small" type="text">
              {selectedAllPages ? 'Select only current page records' : `Select all ${rowsTotal} records`}
            </Button>
          ) : undefined}
        </Space>
      }
      type="warning"
    />
  );
};

export default AlertSelectedRows;
