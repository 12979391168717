import { FC, useEffect, useMemo, useState } from 'react';
import { Button, FormInstance, Tabs } from 'antd';
import { FormBuilderVisibleCondition, FormBuilderWizardFormData } from 'types/formBuilder';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import FormBuilderAnswers, { isFieldVisible } from './FormBuilderAnswers';

interface FormBuilderWizardFormVisibleControlProps {
  formValues: any;
  visible: FormBuilderVisibleCondition;
  changeVisibility: (visible: boolean) => void;
}

const FormBuilderWizardFormVisibleControl: FC<FormBuilderWizardFormVisibleControlProps> = ({
  formValues,
  visible,
  changeVisibility,
}) => {
  const value = formValues[visible.field];

  useEffect(() => {
    changeVisibility(isFieldVisible(visible, value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, visible]);

  return null;
};

interface FormBuilderWizardFormProps {
  data: FormBuilderWizardFormData[];
  form: FormInstance;
  formValues?: any;
  disabled?: boolean;
}

const FormBuilderWizardForm: FC<FormBuilderWizardFormProps> = ({ form, formValues = {}, data, disabled }) => {
  const [hidingFields, setHidingFields] = useState<string[]>([]);

  const filteredData = useMemo(() => {
    return data.filter((item) => !hidingFields.includes(item.id));
  }, [data, hidingFields]);

  const [activeKey, setActiveKey] = useState(filteredData.length > 0 ? filteredData[0].id : undefined);

  const currentDataIndex = filteredData.findIndex((x) => x.id === activeKey);

  return (
    <>
      {data.map(
        (item) =>
          item.visible && (
            <FormBuilderWizardFormVisibleControl
              key={item.id}
              formValues={formValues}
              visible={item.visible}
              changeVisibility={(visible) => {
                if (visible && hidingFields.includes(item.id)) {
                  setHidingFields((prev) => prev.filter((x) => x !== item.id));
                }

                if (!visible && !hidingFields.includes(item.id)) {
                  setHidingFields((prev) => [...prev, item.id]);
                }
              }}
            />
          ),
      )}

      <Tabs activeKey={activeKey} type="card" onChange={setActiveKey}>
        {filteredData.map((item) => (
          <Tabs.TabPane key={item.id} tab={item.title} disabled={activeKey !== item.id}>
            <FormBuilderAnswers form={form} formValues={formValues} data={item.form} disabled={disabled} />
          </Tabs.TabPane>
        ))}
      </Tabs>

      {filteredData.length > 0 && (
        <div className="flex justify-end gap-2">
          {filteredData[0].id !== activeKey && (
            <Button
              type="primary"
              onClick={() =>
                form
                  .validateFields()
                  .then(() => setActiveKey(filteredData[currentDataIndex - 1].id))
                  .catch((error) => asyncErrorHandler(error))
              }
            >
              Back
            </Button>
          )}

          {filteredData[filteredData.length - 1].id === activeKey ? (
            <Button
              type="primary"
              onClick={() =>
                form
                  .validateFields()
                  .then((values) => console.log('values', values))
                  .catch((error) => asyncErrorHandler(error))
              }
            >
              Submit
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() =>
                form
                  .validateFields()
                  .then(() => setActiveKey(filteredData[currentDataIndex + 1].id))
                  .catch((error) => asyncErrorHandler(error))
              }
            >
              Next
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default FormBuilderWizardForm;
