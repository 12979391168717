import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

interface UseGridColumnsLinearOptions {
  length: number;
}

const useGridColumnsLinear = ({ length }: UseGridColumnsLinearOptions) => {
  const breakpoint = useBreakpoint();

  if (!breakpoint.sm) {
    return 2;
  }

  if (!breakpoint.md) {
    return 3;
  }

  const columFive = length % 5;
  const columnFour = length % 4;
  const columnTree = length % 3;

  if (columFive !== 0) {
    if (columnFour === 0) {
      return 4;
    }

    if (columnTree === 0) {
      return 3;
    }

    if (columnFour > columFive && columnFour > columnTree) {
      return 4;
    }

    if (columnTree > columFive && columnTree > columnFour) {
      return 3;
    }
  }

  return 5;
};

export default useGridColumnsLinear;
