/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, FC, useEffect, useState } from 'react';
import { Button, Dropdown, DropDownProps, Form, Row, Space, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import TimerForm from 'components/Timer/TimerForm';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import { convertHrsMinsToMins, convertMinsToHrsMins } from 'utils/string.utils';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { setGlobalLogTrigger } from 'store/container/globalLog/globalLog-slice';
import styles from './EditTimerDropdown.module.less';

interface EditTimerDropdownProps {
  timelog: any;
  modelType: 'task' | 'request';
  style?: CSSProperties;
  placement?: DropDownProps['placement'];
  showUserSelection?: boolean;
  onUpdate?: any;
}

const EditTimerDropdown: FC<EditTimerDropdownProps> = ({ timelog, modelType, style, placement, showUserSelection, onUpdate }) => {
  const [saving, setSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const uuid = timelog?.uuid;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      date: moment(timelog?.date),
      time: convertMinsToHrsMins(timelog?.minutes),
      description: timelog?.description ?? timelog?.comment,
      user_id: timelog?.user_uuid ?? timelog?.user?.uuid,
    });
  }, [uuid]);

  const updateTimer = async () => {
    setSaving(true);

    try {
      const values = await form.validateFields();

      await apiRequests.put(`${modelType === 'task' ? apiRoutes.TIME_LOG : apiRoutes.REQUEST_TIME_LOG}/${uuid}`, {
        ...values,
        date: moment(values.date).format('YYYY-MM-DD'),
        minutes: convertHrsMinsToMins(values.time),
      });

      dispatch(setGlobalLogTrigger());

      setVisible(false);

      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      {visible && (
        <div
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
            setVisible(false);
          }}
        />
      )}
      <Dropdown
        placement={placement}
        overlay={
          <Spin spinning={saving}>
            <div className={styles.overlay}>
              <TimerForm
                form={form}
                initialUser={
                  timelog
                    ? {
                        name: timelog.user ? timelog.user.name : timelog.name ?? timelog.user_name,
                        uuid: timelog.user ? timelog.user.uuid : timelog.user_uuid,
                      }
                    : undefined
                }
                showUserSelection={showUserSelection ? modelType : undefined}
                requiredDescription={modelType === 'task'}
              />

              <Row justify="end">
                <Space>
                  <Button onClick={updateTimer} loading={saving} type="primary">
                    Update Timer
                  </Button>
                  <Button onClick={() => setVisible(false)}>Cancel</Button>
                </Space>
              </Row>
            </div>
          </Spin>
        }
        visible={visible}
      >
        <Button
          type="link"
          size="small"
          style={style}
          icon={<EditOutlined />}
          onClick={() => setVisible(true)}
          loading={saving}
        />
      </Dropdown>
    </>
  );
};

export default EditTimerDropdown;
