import { FC, useEffect, useMemo, useRef } from 'react';
import { Checkbox, Form, FormInstance, Radio, RadioGroupProps } from 'antd';
import { FormBuilderFieldRadio } from 'types/formBuilder';
import useGridColumnsLinear from './useGridColumnsLinear';
import { listIcons } from './FieldCheckbox';

const modeClassName: Record<FormBuilderFieldRadio['mode'], string> = {
  simple: '',
  pill: 'radio-group-pill',
  square: 'radio-group-square',
};

interface FieldRadioProps {
  id?: string;
  value?: RadioGroupProps['value'];
  data: FormBuilderFieldRadio;
  form: FormInstance;
  disabled: boolean;
  onChange?: RadioGroupProps['onChange'];
}

const FieldRadio: FC<FieldRadioProps> = ({ id, value, data, form, disabled, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const boxRef = useRef<any>(null);

  const options = useMemo(() => {
    return data.options.map((item) => ({
      ...item,
      label: (
        <div style={{ textAlign: 'center' }}>
          {item.imageUrl ? (
            <>
              <img src={item.imageUrl} style={{ width: '40px', display: 'inline-block' }} alt="icon" />
              <br />
            </>
          ) : undefined}

          {item.icon ? (
            <>
              {listIcons[item.icon]}
              {data.mode === 'square' ? <br /> : undefined}
            </>
          ) : undefined}

          {item.label}
        </div>
      ),
    }));
  }, [data.mode, data.options]);

  useEffect(() => {
    if (ref.current) {
      const unsubscribe = Array.from(ref.current.children).map((item) => {
        const input = item.querySelector('input');

        let timeout: NodeJS.Timeout;

        const listener = () => {
          const formValue = form.getFieldValue(data.name);

          let inputValue: any = input?.value;

          if (typeof formValue === 'boolean') {
            inputValue = Boolean(inputValue);
          }

          if (typeof formValue === 'number') {
            inputValue = Number(inputValue);
          }

          if (inputValue === form.getFieldValue(data.name)) {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
              form.setFieldsValue({
                [data.name]: undefined,
              });
              boxRef.current?.input?.click();
            }, 0);
          }
        };

        item.addEventListener('click', listener);

        return () => {
          item.removeEventListener('click', listener);
        };
      });

      return () => {
        unsubscribe.forEach((callback) => callback());
      };
    }

    return () => {};
  }, [data.name, form]);

  const columnsNumber = useGridColumnsLinear({ length: options.length });

  return (
    <>
      <Form.Item name={`${data.name}_force_render`} valuePropName="checked" hidden>
        <Checkbox ref={boxRef} />
      </Form.Item>

      <Radio.Group
        id={id}
        ref={ref}
        value={value}
        className={`${modeClassName[data.mode]}${disabled ? ' read-only' : ''}`}
        style={
          data.mode === 'square'
            ? {
                display: 'grid',
                gridTemplateColumns: `repeat(${columnsNumber}, 1fr)`,
              }
            : undefined
        }
        options={options}
        onChange={onChange}
      />
    </>
  );
};

export default FieldRadio;
