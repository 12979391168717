import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export interface ReportUserPerformanceFilters {
  dateRange?: [moment.Moment, moment.Moment];
  source: ('task' | 'request')[];
  userTypes: ('regular_user' | 'freelancer')[];
  categories: ('billable' | 'non-billable' | 'sgm-non-billable')[];
  projectType: ('hosting' | 'retainer' | 'others')[];
}

export interface ReportUserPerformanceState {
  loading: boolean;
  data: null | Record<string, any>;
  error: null | any;
  filters: ReportUserPerformanceFilters;
}

const initialState: ReportUserPerformanceState = {
  loading: false,
  data: null,
  error: null,
  filters: {
    dateRange: [moment().subtract(30, 'day').startOf('day'), moment().endOf('day')],
    source: ['task', 'request'],
    userTypes: ['regular_user', 'freelancer'],
    categories: ['billable', 'non-billable', 'sgm-non-billable'],
    projectType: ['hosting', 'retainer', 'others'],
  },
};

export const reportUserPerformanceSlice = createSlice({
  name: 'reportUserPerformance',
  initialState,
  reducers: {
    getReportUserPerformance: () => {},
    setReportUserPerformanceLoading: (state) => ({ ...state, loading: true }),
    setReportUserPerformanceData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setReportUserPerformanceError: (state, action) => ({ ...state, loading: false, error: action.payload }),
    clearReportUserPerformanceData: () => initialState,
    setReportUserPerformanceFilter: (state, action: { payload: Partial<ReportUserPerformanceFilters>; type: string }) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    },
    setReportUserPerformanceFilterDateRange: (
      state,
      action: { payload: [moment.Moment, moment.Moment] | undefined; type: string },
    ) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          dateRange: action.payload,
        },
      };
    },
    resetReportUserPerformanceFilterDropdown: (state) => {
      return {
        ...state,
        filters: { ...initialState.filters, dateRange: state.filters.dateRange },
      };
    },
  },
});

export const {
  setReportUserPerformanceData,
  setReportUserPerformanceError,
  setReportUserPerformanceLoading,
  setReportUserPerformanceFilter,
  setReportUserPerformanceFilterDateRange,
  getReportUserPerformance,
  resetReportUserPerformanceFilterDropdown,
  clearReportUserPerformanceData,
} = reportUserPerformanceSlice.actions;

export default reportUserPerformanceSlice.reducer;
