import { createAppTableSaga, createAppTableStore, createPersist } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';

export const projectAdvertisingSlice = createAppTableStore({
  name: 'projectsAdvertising' as const,
  url: apiRoutes.PROJECTS_ADVERTISING,
  initialState: {
    params: {
      filters: {
        'projects.status': [{ value: 'paused' }, { value: 'waiting_to_launch' }, { value: 'in_progress' }, { value: 'active' }],
        advertising: [{ value: true }],
      },
      sort: { column: 'title', order: 'ascend' },
    },
    ignoreFetch: true,
  },
});

export const projectsAdvertisingSaga = createAppTableSaga(projectAdvertisingSlice);

export const projectsAdvertisingReducer = createPersist(projectAdvertisingSlice);
