/* eslint-disable no-nested-ternary */
import { ReactNode } from 'react';
import { ColumnType } from 'antd/lib/table';
import MinSpan from 'components/MinSpan';

export interface AppTableColumn extends ColumnType<any> {
  title?: string;
  dataIndex: string | string[];
  sorter?: boolean;
  search?: boolean;
  fixed?: 'right' | 'left';
  width?: number;
  render?: (value: any, row: any) => any;
  sorterKey?: string;
  key?: string;
  align?: 'left' | 'right' | 'center';
  editable?: boolean;
  editComponent?: ReactNode;
  onToggleEdit?: any;
  onSaveRecord?: any;
  minSpace?: boolean;
  minSpaceText?: (value: any, row: any) => string;
  exportRender?: boolean | ((item: any) => any);
  exportRenderTheme?: 'status' | 'users' | 'customer' | 'customers' | 'array' | 'titleCaseArray';
  hideFromExport?: boolean;
  customExportKey?: string;
  beforeSave?: (record: Record<string, any>) => Record<string, any>;
}

interface UseColumnsParams {
  columns: AppTableColumn[];
  sort?: { column: string; order: 'ascend' | 'descend' } | null;
  editable?: boolean;
  onSave?: (uuid: string, values: Record<string, any>) => void;
}

const useColumns = ({ columns, sort, editable, onSave }: UseColumnsParams) => {
  return columns.map((el) => ({
    ...el,
    render:
      el.minSpace || !el.render
        ? (value: any, row: any) => (
            <MinSpan
              text={el.minSpaceText ? el.minSpaceText(value, row) : value}
              count={typeof el.title?.length === 'number' ? el.title.length * 2 + 3 : 10}
            >
              {el.render ? el.render(value, row) : value}
            </MinSpan>
          )
        : el?.render,
    onCell: editable
      ? (record: any) => ({
          editable: el.editable ?? true,
          dataIndex: el.dataIndex,
          title: el.title,
          record,
          onSave,
        })
      : el.onCell,
    defaultSortOrder:
      sort && ((Array.isArray(el.dataIndex) ? el.dataIndex?.join('.') : el.dataIndex) === sort.column || el.key === sort.column)
        ? sort.order
        : el.defaultSortOrder,
  }));
};

export default useColumns;
