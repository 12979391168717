import { FC, useRef } from 'react';
import { Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { taskTimelogReportSlice } from 'modules/tasks/store/taskTimelogReport';
import AppTable from 'components/AppTable';
import { AppTableColumn } from 'components/AppTable/useColumns';
import EditTimerDropdown from 'components/Timer/EditTimerDropdown';
import DeleteTimer from 'components/Timer/DeleteTimer';
import { convertMinsToHrsMins, ellipseText, parseDateToUSFormat, renderTime } from 'utils/string.utils';
import { RootState } from 'store';

const { Text } = Typography;

interface TaskTimelogTableProps {
  uuid: string;
}

const TaskTimelogTable: FC<TaskTimelogTableProps> = ({ uuid }) => {
  const totalTime = useSelector((globalState: RootState) => globalState.taskTimelogReport.totalTime);
  const isMountedRef = useRef(false);

  const dispatch = useDispatch();

  if (!isMountedRef.current) {
    isMountedRef.current = true;
    dispatch(taskTimelogReportSlice.actions.restoreInitialState());
    dispatch(
      taskTimelogReportSlice.actions.setFixedParams({
        'filters[source_uuid][]': uuid,
      }),
    );
  }

  const columns: AppTableColumn[] = [
    {
      title: 'Tracked',
      dataIndex: 'minutes',
      width: 100,
      exportRender: true,
      sorter: true,
      render: (value) => renderTime(convertMinsToHrsMins(value)),
    },

    {
      title: 'Date',
      dataIndex: 'date',
      exportRender: true,
      sorter: true,
      render: (value) => parseDateToUSFormat(value),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      minSpace: true,
      sorter: true,
      minSpaceText: (value) => ellipseText(value),
      render: (value) => ellipseText(value),
    },
    {
      title: 'User',
      dataIndex: 'user_name',
      sorter: true,
      render: (value) => <Text className="primary">{value}</Text>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      hideFromExport: true,
      fixed: 'right',
      render: (_: any, row: any) => {
        return (
          <Space size={2}>
            <EditTimerDropdown
              timelog={row}
              modelType={row.source}
              showUserSelection
              onUpdate={() => dispatch(taskTimelogReportSlice.actions.fetch())}
            />
            <DeleteTimer
              uuid={row.uuid}
              modelType={row.source}
              onDelete={() => dispatch(taskTimelogReportSlice.actions.fetch())}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <AppTable
        storeSlice={taskTimelogReportSlice}
        widthX={550}
        prefixPagination={totalTime ? `${totalTime} / ` : undefined}
        textCount="timelogs found"
        columns={columns}
        hideActionColumn
      />
    </div>
  );
};

export default TaskTimelogTable;
