import { CSSProperties, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import apiRoutes from 'config/apiRoute';
import { setGlobalLogTrigger } from 'store/container/globalLog/globalLog-slice';

interface DeleteTimerProps {
  uuid: any;
  modelType: 'task' | 'request';
  style?: CSSProperties;
  onDelete?: any;
}

const DeleteTimer: FC<DeleteTimerProps> = ({ uuid, modelType, style, onDelete }) => {
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  const onDeleteHandler = async () => {
    setDeleting(true);

    try {
      await apiRequests.delete(`${modelType === 'task' ? apiRoutes.TIME_LOG : apiRoutes.REQUEST_TIME_LOG}/${uuid}`);

      dispatch(setGlobalLogTrigger());

      if (onDelete) {
        onDelete();
      }
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Popconfirm onConfirm={onDeleteHandler} title="Do you want to delete this timelog?">
      <Button type="link" size="small" style={style} icon={<DeleteOutlined />} loading={deleting} />
    </Popconfirm>
  );
};

export default DeleteTimer;
