import { createSlice } from '@reduxjs/toolkit';

export const dashboardUserPerformanceSlice = createSlice({
  name: 'dashboardUserPerformance',
  initialState: {
    loading: false as boolean,
    data: null as any,
    error: null as any,
  },
  reducers: {
    getDashboardUserPerformance: () => {},
    setDashboardUserPerformanceLoading: (state) => ({ ...state, loading: true }),
    setDashboardUserPerformanceData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setDashboardUserPerformanceError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const {
  setDashboardUserPerformanceData,
  setDashboardUserPerformanceError,
  setDashboardUserPerformanceLoading,
  getDashboardUserPerformance,
} = dashboardUserPerformanceSlice.actions;

export default dashboardUserPerformanceSlice.reducer;
