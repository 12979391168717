import { FC, ReactNode, useMemo } from 'react';
import { Checkbox, CheckboxOptionType } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import { FaFacebook, FaGoogle, FaInstagram, FaLinkedin, FaTiktok, FaYoutube } from 'react-icons/fa';
import { IoImageOutline } from 'react-icons/io5';
import { TbCarouselHorizontal, TbDeviceMobile, TbGif, TbQuestionMark, TbVideo } from 'react-icons/tb';
import { BiFilm } from 'react-icons/bi';
import { FaRadio } from 'react-icons/fa6';
import { FormBuilderFieldCheckbox } from 'types/formBuilder';
import useGridColumnsLinear from './useGridColumnsLinear';

const modeClassName: Record<FormBuilderFieldCheckbox['mode'], string> = {
  simple: '',
  pill: 'checkbox-group-pill',
  square: 'checkbox-group-square',
};

interface FieldCheckboxProps {
  id?: string;
  value?: CheckboxGroupProps['value'];
  data: FormBuilderFieldCheckbox;
  disabled: boolean;
  onChange?: CheckboxGroupProps['onChange'];
}

const listIcons: Record<string, ReactNode> = {
  google: <FaGoogle />,
  youtube: <FaYoutube />,
  facebook: <FaFacebook />,
  instagram: <FaInstagram />,
  radio: <FaRadio />,
  ott: <FaGoogle />,
  linkedin: <FaLinkedin />,
  tiktok: <FaTiktok />,
  video: <TbVideo />,
  film: <BiFilm />,
  image: <IoImageOutline />,
  carousel: <TbCarouselHorizontal />,
  gif: <TbGif />,
  question: <TbQuestionMark />,
  phone: <TbDeviceMobile />,
  phoneLandscape: <TbDeviceMobile style={{ rotate: '-90deg' }} />,
};

const FieldCheckbox: FC<FieldCheckboxProps> = ({ id, value, data, disabled, onChange }) => {
  const options = useMemo(() => {
    return data.options.map(
      (item): CheckboxOptionType => ({
        ...item,
        label: (
          <div style={{ textAlign: 'center' }}>
            {item.imageUrl ? (
              <>
                <img
                  src={item.imageUrl}
                  style={{
                    display: 'inline-block',
                    width: data.mode === 'square' ? '40px' : 16,
                    marginRight: data.mode !== 'square' ? 6 : undefined,
                    verticalAlign: data.mode !== 'square' ? 'text-bottom' : undefined,
                  }}
                  alt="icon"
                />
                {data.mode === 'square' ? <br /> : undefined}
              </>
            ) : undefined}

            {item.icon ? (
              <>
                {listIcons[item.icon]}
                {data.mode === 'square' ? <br /> : undefined}
              </>
            ) : undefined}

            {item.label}
          </div>
        ),
        onChange: (event) => {
          if (!event.target.checked) {
            return;
          }

          const uniqueOptions = data.options.filter((option) => option.unique);

          if (uniqueOptions.length) {
            const optionTarget = uniqueOptions.find((option) => option.value === event.target.value);

            if (optionTarget) {
              setTimeout(() => {
                onChange?.([optionTarget.value]);
              }, 0);
            } else {
              const filtered = value?.filter((option) => !uniqueOptions.find((uniqueOption) => uniqueOption.value === option));

              if (value?.length !== filtered?.length) {
                setTimeout(() => {
                  onChange?.([...(filtered ?? []), event.target.value]);
                }, 0);
              }
            }
          }
        },
      }),
    );
  }, [data.mode, data.options, onChange, value]);

  const columnsNumber = useGridColumnsLinear({ length: options.length });

  return (
    <div id={id}>
      <Checkbox.Group
        className={`${modeClassName[data.mode]}${disabled ? ' read-only' : ''}`}
        style={
          data.mode === 'square'
            ? {
                display: 'grid',
                gridTemplateColumns: `repeat(${columnsNumber}, 1fr)`,
              }
            : undefined
        }
        value={value}
        options={options}
        onChange={(values) => {
          if (data.max && values.length > data.max) {
            return;
          }

          onChange?.(values);
        }}
      />
    </div>
  );
};

export default FieldCheckbox;

export { listIcons };
