import { FC, MouseEvent, useMemo, useState } from 'react';
import { Row, Tooltip, Typography, Popconfirm, Col } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import UserAvatar from 'components/UserAvatar';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { convertMinsToHrsMins, parseDateToUSFormat, renderTime } from 'utils/string.utils';
import style from './index.module.less';
import StatusTag from '../../../../components/StatusTag';

const { Title, Text } = Typography;

interface RequestTaskCardProps {
  requestUuid: string;
  task: any;
  listTasks: any[];
  margin?: boolean;
  onRemoved: () => void;
}

const RequestTaskCard: FC<RequestTaskCardProps> = ({ requestUuid, task, listTasks, margin = false, onRemoved }) => {
  const [removing, setRemoving] = useState(false);
  const responsible = task.related_users.find((el: any) => el.pivot.type === 'responsible');

  const totalTimeSubTasks = useMemo((): number => {
    const getTasksTotalTime = (subtasks: any[]): number => {
      return subtasks.reduce<number>(
        (acc, subtask: any) =>
          acc +
          (listTasks.find(({ task: item }) => item.uuid === subtask.uuid) ? subtask.total_timelogs : 0) +
          getTasksTotalTime(subtask.subtasks),
        0,
      );
    };

    return getTasksTotalTime(task.subtasks);
  }, [listTasks, task.subtasks]);

  const removeClickHandler = async (event: MouseEvent<HTMLElement> | undefined) => {
    event?.stopPropagation();

    setRemoving(true);

    try {
      await apiRequests.put(`${apiRoutes.PROJECT_TASKS}/${task.uuid}`, { remove_requests: [requestUuid] });

      onRemoved();
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setRemoving(false);
    }
  };

  return (
    <Link
      to={`?task_uuid=${task.uuid}`}
      className={style.card}
      style={{
        borderLeftColor: `var(--priority-${task.priority}-color)`,
        marginLeft: margin ? 24 : undefined,
        marginTop: margin ? -4 : undefined,
      }}
    >
      <Row wrap={false} justify="space-between">
        <Title level={5} className="no-margin">
          {task.title}
        </Title>

        <Popconfirm
          disabled={removing}
          title="Do you want to remove this task from the request?"
          onConfirm={removeClickHandler}
          onCancel={(event) => event?.stopPropagation()}
        >
          <CloseCircleOutlined className="red pointer" style={{ fontSize: 20 }} />
        </Popconfirm>
      </Row>

      <Row gutter={[8, 8]} justify="space-between">
        <Col>
          <Text type="secondary">
            {task.project.title} - {task.project.customer.abbreviation}
          </Text>
        </Col>

        <Col>
          <StatusTag size="small" value={task.status} />
        </Col>
      </Row>

      <Row justify="space-between" style={{ paddingTop: 4 }}>
        {responsible ? (
          <Tooltip title={responsible.name} placement="top">
            <UserAvatar size="small" user={responsible} />
          </Tooltip>
        ) : (
          '-'
        )}

        <span>M: {renderTime(convertMinsToHrsMins(task.total_timelogs))}</span>

        <span style={{ visibility: !totalTimeSubTasks ? 'hidden' : undefined }}>
          S: {renderTime(convertMinsToHrsMins(totalTimeSubTasks))}
        </span>

        <span>{parseDateToUSFormat(task.created_at)}</span>
      </Row>
    </Link>
  );
};

export default RequestTaskCard;
