import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, message } from 'antd';
import { FormBuilderData } from 'types/formBuilder';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';
import appRoutes from 'config/appRoutes';
import RequestForm, { RequestFormProps } from '../RequestForm';

interface RequestUpdateDraftProps {
  request: Record<string, any>;
  formBuilder?: FormBuilderData;
  projectUuid?: string;
  pageUrl: string;
  resetForm?: RequestFormProps['resetForm'];
  goBack?: boolean;
  noPadding?: boolean;
  onSaveRequest: (values: any) => Promise<Record<string, any>>;
}

const RequestUpdateDraft: FC<RequestUpdateDraftProps> = ({
  request,
  formBuilder,
  projectUuid,
  pageUrl,
  resetForm,
  goBack,
  noPadding,
  onSaveRequest,
}: RequestUpdateDraftProps) => {
  const history = useHistory();

  const onDiscard = async () => {
    return apiRequests.delete(`${apiRoutes.REQUESTS}/${request.uuid}`).then(() => {
      message.success('Draft deleted!');

      history.push(appRoutes.REQUESTS);
    });
  };

  return (
    <Card bodyStyle={{ padding: noPadding ? '0 0 8px' : undefined, paddingBottom: !noPadding ? 8 : undefined }} bordered={false}>
      <RequestForm
        type="draft"
        request={request}
        formBuilder={formBuilder}
        projectUuid={projectUuid}
        pageUrl={pageUrl}
        resetForm={resetForm}
        goBack={goBack}
        onDiscard={onDiscard}
        onSubmit={onSaveRequest}
        onUpdate={onSaveRequest}
      />
    </Card>
  );
};

export default RequestUpdateDraft;
